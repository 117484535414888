import { lazy } from "react";
// default Tungsten Pages
export const RegisteredUsers = lazy(() => import('./RegisteredUsers'))
export const Users = lazy(() => import('./Users'))
export const SentEmails = lazy(() => import('./SentEmails'))
export const SentSMS = lazy(() => import('./SentSMS'))
export const SystemSettings = lazy(() => import('./SystemSettings'))
export const Companies = lazy(() => import('./Companies'))
export const Reports = lazy(() => import('./Reports'))
export const Dashboard = lazy(() => import('./Dashboard'))
export const Widgets = lazy(() => import('./Widgets'))
export const Invoices = lazy(() => import('./Invoices'))
export const Bills = lazy(() => import('./Bills'))
export const PermissionPresets = lazy(() => import('./PermissionPresets'))
export const EmailTemplates = lazy(() => import('./EmailTemplates'))
export const SMSTemplates = lazy(() => import('./SMSTemplates'))
export const DocumentTemplates = lazy(() => import('./DocumentTemplates'))
export const PaymentAccounts = lazy(() => import('./PaymentAccounts'))
export const PaymentRuns = lazy(() => import('./PaymentRuns'))
export const TimeLogs = lazy(() => import('./TimeLogs'))
export const Budget = lazy(() => import('./Budget'))
export const BackgroundProcesses = lazy(() => import('./BackgroundProcesses'))
export const DisconnectedProcesses = lazy(() => import('./DisconnectedProcesses'))

// customize Pages
export const EventTypes = lazy(() => import('./EventTypes'))
export const Events = lazy(() => import('./Events'))
export const CaseWorks = lazy(() => import('./CaseWorks'))
export const WaitingLists = lazy(() => import('./WaitingLists'))
export const Referrals = lazy(() => import('./Referrals'))
export const AvailableTags = lazy(() => import('./AvailableTags'))
export const AvailableTagGroups = lazy(() => import('./AvailableTagGroups'))
export const DocumentTypes = lazy(() => import('./DocumentTypes'))
export const Medications = lazy(() => import('./Medications'))
export const Programs = lazy(() => import('./Programs'))