import { gql } from "@apollo/client"
import { 
  EmailTemplatesDropdownQuery, 
  JobRolesDropdownQuery,
  OrganisationsDropdownQuery,
  SentryProjectDropdownQuery,
  OrganisationTypesDropdownQuery,
  ParameterTypesDropdownQuery,
  PermissionsDropdownQuery,
  UserTypesDropdownQuery,
  MobileCountriesDropdownQuery,
  CountriesDropdownQuery,
  TitlesDropdownQuery,
  ApplicationAreasDropdownQuery,
  UsersDropdownQuery,
  AccountSystemContactsDropdownQuery,
  InvoicingModesDropdownQuery,
  BudgetAccountCodeTypesDropdownQuery,
  BudgetAccountCodesDropdownQuery,
  BudgetVersionDropdownQuery,
  ColourPalettesDropdownQuery,
  FontAwesomeIconsDropdownQuery,

  // Custom Dropdown Start

  // Custom Dropdown End
} from "@services/query/DropdownQueries"
import { DashboardListGridFormattingOptionsDropdownQuery } from "@services/query/grid/DashboardListGridsQuery"
import { DashboardGroupsDropdownQuery } from "@services/query/grid/DashboardQuery"
import { TemplateModesDropdownQuery } from "@services/query/grid/EmailTemplatesQuery"
import { PaymentAccountsDropdownQuery } from "@services/query/grid/PaymentAccountsQuery"
import { PermissionPresetsDropdownQuery } from "@services/query/grid/PermissionsQuery"
import { ReportFormattingOptionsDropdownQuery, ReportGroupsDropdownQuery, ReportParameterTypesDropdownQuery, ReportParameterValuesDropdownQuery, ReportWildcardModesDropdownQuery } from "@services/query/grid/ReportsQuery"

export type EntityCachePropType = { 
    ID: number; 
    EntityName: string;
}

export const EntityCachesQuery = gql`
query (
    $where: EntityCacheFilterInput
  ) {
    EntityCaches (
        where: $where
    ) {
      totalCount
      items {
        ID
        EntityName
      }
    }
  }
`

export const cacheQueries = {
  FontAwesomeIcon: [
    FontAwesomeIconsDropdownQuery
  ],
  ColourPalette: [
    ColourPalettesDropdownQuery
  ],
  Organisation: [
    OrganisationsDropdownQuery
  ],
  SentryProject: [
    SentryProjectDropdownQuery
  ],
  OrganisationType: [
    OrganisationTypesDropdownQuery
  ],
  ParameterType: [
    ParameterTypesDropdownQuery
  ],
  Permission: [
    PermissionsDropdownQuery
  ],
  JobRole: [
    JobRolesDropdownQuery
  ],
  UserType: [
    UserTypesDropdownQuery
  ],
  Country: [
    MobileCountriesDropdownQuery,
    CountriesDropdownQuery
  ],
  Title: [
    TitlesDropdownQuery
  ],
  ApplicationArea: [
    ApplicationAreasDropdownQuery
  ],
  User: [
    UsersDropdownQuery
  ],
  AccountSystemContact: [
    AccountSystemContactsDropdownQuery
  ],
  InvoicingMode: [
    InvoicingModesDropdownQuery
  ],
  AccountCodeType: [
    BudgetAccountCodeTypesDropdownQuery
  ],
  AccountCode: [
    BudgetAccountCodesDropdownQuery
  ],
  BudgetVersion: [
    BudgetVersionDropdownQuery
  ],
  EmailTemplate: [
    EmailTemplatesDropdownQuery
  ],
  DashboardListGridFormattingOption: [
    DashboardListGridFormattingOptionsDropdownQuery
  ],
  DashboardGroup: [
    DashboardGroupsDropdownQuery
  ],
  TemplateMode: [
    TemplateModesDropdownQuery
  ],
  PaymentAccount: [
    PaymentAccountsDropdownQuery
  ],
  PermissionPreset: [
    PermissionPresetsDropdownQuery
  ],
  ReportGroup: [
    ReportGroupsDropdownQuery
  ],
  ReportParameterType: [
    ReportParameterTypesDropdownQuery
  ],
  ReportWildcardMode: [
    ReportWildcardModesDropdownQuery
  ],
  ReportFormattingOption: [
    ReportFormattingOptionsDropdownQuery
  ],
  ReportParameterValue: [
    ReportParameterValuesDropdownQuery
  ],

  // Custom Dropdown Start
  // Custom Dropdown End
}