import { gql } from "@apollo/client";

export const MenuListQuery = gql`
  query($MenuID: Int) {
    MenuItems(MenuID: $MenuID) {
      items {
        ID
        Title
        URL
        Icon
        IsNavMenu
        LaunchExternal
        MenuID
        ParentMenuItemID  
        ListOrder
      }
    }
  }
`;
