/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo} from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import objectPath from "object-path";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {UserProfileDropdown} from "./dropdowns/UserProfileDropdown";
import * as cases from "@whitecobalt/tungsten/utils/case";
import { useSession } from "@whitecobalt/tungsten/Session";
import { UserNotificationsDropdown } from "./dropdowns/UserNotificationsDropdown";
import FileKeyLoader from "@whitecobalt/tungsten/FileKeyLoader";
import classNames from "classnames";
import { capitalize } from "./offcanvas/QuickUser";

export function QuickUserToggler() {
  const [{user, others}] = useSession();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  return (<>
        <UserNotificationsDropdown />
        {layoutProps.offcanvas && (<OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="quick-user-tooltip">View user</Tooltip>}
        >
          <div className="topbar-item">
            <div className={classNames("btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2", { 'no-event': !others.menuListLoaded })}
                 id="kt_quick_user_toggle">
              <>

                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                  {user && `${user.title ? `${user.title}. ` : ''}${capitalize(user?.firstname)} ${capitalize(user?.surname)}`}
                </span>
                {/* <span className="symbol symbol-35 symbol-light-success">  */}
                <span className="symbol symbol-35 symbol-light-dark">
                    {user.photo ? (
                      <FileKeyLoader fileKey={user.photo} style={{maxHeight: 35, maxWidth: 35}}/>
                    ) : (
                      <span className="symbol-label font-size-h5 font-weight-bold">{user?.firstname[0].toUpperCase()}</span>
                    )}               
                </span>
              </>
            </div>
          </div>
        </OverlayTrigger>)}

        {!layoutProps.offcanvas && (<UserProfileDropdown/>)}
      </>
  );
}
