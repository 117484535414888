import React, { useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
import Button from '@whitecobalt/tungsten/Button'
import Toaster from '@whitecobalt/tungsten/Toaster'
import FileField from '@whitecobalt/tungsten/Fields/FileField'
import MaskField from '@whitecobalt/tungsten/Fields/MaskField'
import FormManager from '@whitecobalt/tungsten/FormManager'
import SelectDropdown from '@whitecobalt/tungsten/Fields/SelectDropdown'
import { useSession, SessionActionType } from '@whitecobalt/tungsten/Session'
import { useAuthAPI } from '@whitecobalt/tungsten/hooks/useAuthAPI'
import { MobileCountriesDropdownQuery, TitlesDropdownQuery } from '@services/query/DropdownQueries'
import branding from '@config/branding'
import MFAActivation from './SecurityAndPrivacy/MFAActivation'
import DataList from '@whitecobalt/tungsten/Fields/DataList'
import PasswordField from '@whitecobalt/tungsten/Fields/PasswordField'
import OneSignalSubscription from './SecurityAndPrivacy/OneSignalSubscription'
import TOTPGoogleAuth from './SecurityAndPrivacy/TOTPGoogleAuth'
import ChangeEmailButton from './ChangeEmailButton'
import TimeLogs from './TimeLogs'
import { Link, useLocation } from 'react-router-dom'

const UserProfile: React.FunctionComponent = () => {
    const location = useLocation()
    const [{ user }, dispatch] = useSession()
    const requestUpdateProfile = useAuthAPI('/api/SaveUser')
    const requestChangePassword = useAuthAPI('/api/ChangePassword')

    const handleUpdateProfile = (event: any) => {
        const FORM_DATA = event.json()

        const payload = {
            original: {
                "id": user?.id,
                "photo": user?.photo
            },
            new: {
                "id": user?.id,
                "title": FORM_DATA.title,
                "firstname": FORM_DATA.firstname,
                "surname": FORM_DATA.surname,
                "emailAddress": user?.emailAddress,
                "jobTitle": FORM_DATA.jobTitle,
                "phoneNumber": FORM_DATA.phoneNumber,
                "mobileNumber": FORM_DATA.mobileNumber,
                "mobileCountryID": FORM_DATA.mobileCountryID,
                "photo": FORM_DATA.photo ? FORM_DATA.photo[0] : undefined
            },
        }

        const toast = Toaster(`Updating Profile`)
        requestUpdateProfile.call({ data: payload }).then((response) => {
            if (response.data.success) {
                toast.success(`Successfully updated Profile`)
                dispatch({
                    type: SessionActionType.SET_USER,
                    payload: { ...user, ...payload.new }
                })
            } else {
                FormManager.setBackendValidation(event, response.data.errors, {
                    'Firstname': 'firstname',
                    'Surname': 'surname',
                    'EmailAddress': 'emailAddress',
                    'EmergencyEmail': 'emailAddress'
                })

                toast.fail(response?.data?.message || branding.messages.fail)
            }
        })
        .catch((error) => {
            const errorMessage = error?.response?.data?.message || branding.messages.error
            toast.error(errorMessage);
        })

    }

    const handleChangePassword = (event: any) => {
        const FORM_DATA = event.json()

        const payload = {
            "userID": user?.id,
            "currentPassword": FORM_DATA.currentPassword,
            "newPassword": FORM_DATA.newPassword,
            "confirmPassword": FORM_DATA.confirmPassword
        }

        const toast = Toaster(`Changing Password`)
        requestChangePassword.call({ data: payload }).then((response) => {
            if (response.data.success) {
                toast.success(`Successfully changed Password`)
                event.resetForm()
            } else {
                FormManager.setBackendValidation(event, response.data.errors, {
                    'CurrentPassword': 'currentPassword',
                    'NewPassword': 'newPassword',
                    'ConfirmPassword': 'confirmPassword',
                })

                toast.fail(response?.data?.message || branding.messages.fail)
            }
        })
        .catch((error) => {
            const errorMessage = error?.response?.data?.message || branding.messages.error
            toast.error(errorMessage);
        })

    }

    const [alreadyOpenOnce, setAlreadyOpenOnce] = useState(false)

    useEffect(() => {
        if(alreadyOpenOnce) return;

        const toggleBtn = document.getElementById('kt_quick_user_toggle')
        
        const handleToggle = () => {
            setAlreadyOpenOnce(true)
        }

        toggleBtn?.addEventListener('click', handleToggle, false)
        
        return () => {
            toggleBtn?.removeEventListener('click', handleToggle, false)
        }
        
    }, [alreadyOpenOnce])


    /* Timezone */
    const now = new Date();
    const utcOffsetMinutes = now.getTimezoneOffset();
    const sign = utcOffsetMinutes < 0 ? '+' : '-';
    const hoursOffset = Math.abs(Math.floor(utcOffsetMinutes / 60));
    const minutesOffset = Math.abs(utcOffsetMinutes % 60);

    const timeZoneString = `${sign}${String(hoursOffset).padStart(2, '0')}:${String(minutesOffset).padStart(2, '0')}`;

    return alreadyOpenOnce ? (
        <Tab.Container defaultActiveKey="profile">
            <Nav className="nav-tabs nav-bold nav-tabs-line" defaultActiveKey="profile">
                <Nav.Item className="flex-1">
                    <Nav.Link className="justify-content-center" eventKey="profile">Profile</Nav.Link>
                </Nav.Item>
                <Nav.Item className="flex-1">
                    <Nav.Link className="justify-content-center" eventKey="password">Security</Nav.Link>
                </Nav.Item>
                {branding.timeTracking && (
                    <Nav.Item className="flex-1">
                        <Nav.Link className="justify-content-center" eventKey="time-logs">Time Logs</Nav.Link>
                    </Nav.Item>
                )}
            </Nav>
            <Tab.Content>
                <Tab.Pane eventKey="profile">
                    {branding.services.onesignal && (
                        <>
                            <OneSignalSubscription />
                            <div className="separator separator-dashed mt-8 mb-5" />
                        </>
                    )}
                    <FormManager onSubmit={handleUpdateProfile}>
                        <FormManager.Input
                            name="title"
                            label="Title"
                            value={user?.title}
                            list="titles"
                            data-lpignore="true"
                            description="Input your Title here"
                            />
                        <DataList.Graph gql={TitlesDropdownQuery} fetchPolicy="cache-first" queryKey="dropdown" optionKey="label" id="titles" />
                        <FormManager.Input
                            formGroupClassName="mb-3"
                            name="firstname"
                            label="First Name"
                            value={user?.firstname}
                            length={100}
                            description="Input your First Name here" />
                        <FormManager.Input
                            formGroupClassName="mb-3"
                            name="surname"
                            label="Surname"
                            value={user?.surname}
                            length={100}
                            description="Input your Surname here" />
                        <FormManager.Input
                            formGroupClassName="mb-3"
                            name="emailAddress"
                            label="Email Address"
                            value={user?.emailAddress}
                            disabled
                            length={100}
                            description="Input your Email Address here" />
                        <ChangeEmailButton />
                        <FormManager.Input
                            formGroupClassName="mb-3"
                            name="jobTitle"
                            label="Job Title"
                            value={user?.jobTitle || ""}
                            length={100}
                            description="Input your Job Title here" />
                        <FormManager.Input
                            formGroupClassName="mb-3"
                            name="phoneNumber"
                            label="Phone Number"
                            value={user?.phoneNumber}
                            description="Input your Phone Number here" 
                        />
                        <FormManager.Input
                            prepend={(
                                <FormManager.Input
                                    as={SelectDropdown.Graph}
                                    gql={MobileCountriesDropdownQuery}
                                    fetchPolicy="cache-first"
                                    variables={{
                                        order: { ID: "ASC" }
                                    }}
                                    name="mobileCountryID"
                                    stripped
                                    clearable={false}
                                    value={user?.mobileCountryID || 1}
                                    manageOptions={(item: any) => ({ ...item, label: `+${item.label}` })}
                                />
                            )}
                            formGroupClassName="mb-3"
                            // as={MaskField}
                            // focusSelectAll
                            // masked="9999 999999"
                            mutateOnChange={(event: any) => {
                                return {
                                    name: event.target.name,
                                    value: event.target.value.replace(/[\D]/g, '')
                                }
                            }}
                            length={11}
                            name="mobileNumber"
                            label="Mobile Number"
                            value={(user?.mobileNumber || '').length === 11 ? user?.mobileNumber : (user?.mobileNumber || '').length === 10 ? user?.mobileNumber!.substr(0, 4) + " " + user?.mobileNumber!.substr(4) : undefined}
                            description="Input your Mobile number here" />
                        <FormManager.Input
                            formGroupClassName="mb-3"
                            name="timeZone"
                            disabled
                            label="Timezone"
                            value={`${Intl.DateTimeFormat().resolvedOptions().timeZone} (UTC ${timeZoneString})`}
                            description="Timezone you are in." 
                        />
                        <FormManager.Input
                            formGroupClassName="mb-3"
                            as={FileField}
                            name="photo"
                            label="Photo"
                            value={user?.photo}
                            previews={user?.photo}
                            wrappedValueOnArray
                            defaultValue={[]}
                            accept="image/*"
                            placeholder="A photo of yourself here" />
                        <div className="d-flex justify-content-end">
                            <Button type="submit" loading={requestUpdateProfile.loading} variant="primary">
                                Update
                            </Button>
                        </div>
                    </FormManager>
                </Tab.Pane>
                <Tab.Pane eventKey="password">
                    <MFAActivation />
                    <TOTPGoogleAuth />
                    <div className="separator separator-dashed mt-8 mb-5" />
                    <FormManager onSubmit={handleChangePassword}>
                        <FormManager.Input
                            as={PasswordField}
                            formGroupClassName="mb-3"
                            name="currentPassword"
                            label="Current Password"
                            description="Input your Current Password here" />
                        <FormManager.Input
                            as={PasswordField}
                            formGroupClassName="mb-3"
                            name="newPassword"
                            label="New Password"
                            description="Input your New Password here" />
                        <FormManager.Input
                            as={PasswordField}
                            formGroupClassName="mb-3"
                            name="confirmPassword"
                            label="Confirm New Password"
                            description="Input your Confirm Password here" />
                        <div className="d-flex justify-content-end">
                            <Button type="submit" loading={requestChangePassword.loading} variant="primary">
                                Change Password
                            </Button>
                        </div>
                    </FormManager>
                    
                </Tab.Pane>
                {branding.timeTracking && (
                    <Tab.Pane eventKey="time-logs" mountOnEnter unmountOnExit>
                        <TimeLogs />
                        {location.pathname !== '/time-logs' && (
                            <Link to="/time-logs" onClick={() => document.getElementById('kt_quick_user_toggle')?.click()} className="btn btn-primary btn-block justify-content-center">Show More!</Link>
                        )}
                    </Tab.Pane>
                )}
            </Tab.Content>
        </Tab.Container>
    ) : null
}

export default UserProfile