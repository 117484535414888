import branding from "@config/branding"
import { useAuthAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAuthAPI"
import cookie from "@whitecobalt/tungsten/esm/common/utils/cookie"
import ModalConfirm from "@whitecobalt/tungsten/esm/components/Modal/Confirm"
import ModalView from "@whitecobalt/tungsten/esm/components/Modal/View"
import { SessionActionType, useSession } from "@whitecobalt/tungsten/esm/components/Session"
import Toaster from "@whitecobalt/tungsten/esm/components/Toaster"
import { useHistory } from "react-router-dom"

export const useImpersonation = () => {
    const requestImpersonate = useAuthAPI('/api/Impersonation')
    const history = useHistory()
    const [{token, others}, dispatch] = useSession()

    return {
        ...requestImpersonate,
        call: (payload: {
            userID: number;
            organisationID: number,
            applicationAreaID: number;
          }) => {
            const toaster = Toaster("Impersonating user")
            return requestImpersonate.call({
                data: {
                    ...payload
                }
            }).then((response) => {
                if(response.data.success) {
                    toaster.success("Successfully impersonated the user")
                    cookie.unset(branding.token.name)
                    cookie.unset(branding.token.name+"OG")
                    cookie.unset("organisationIDOG")
                    localStorage.removeItem('expireTimestamp')
                    window.location.assign(`${window.location.origin}/auth/impersonation/${token}/${response.data.token}/${others.organisationID}/${btoa(window.location.href)}`)
                    dispatch({
                        type: SessionActionType.SET_TOKEN,
                        payload: null
                    })
                    dispatch({
                        type: SessionActionType.SET_OTHERS,
                        payload: {
                            ...others,
                            hasSelectedOrganisation: false
                        }
                    })
                    localStorage.removeItem('selected-organisation')
                } else {
                    toaster.fail()
                }
            })
            .catch(() => {
                toaster.error()
            })
        }
    }
}

export const useRevokeImpersonation = () => {
    const [{others}, dispatch] = useSession()
    
    return () => {
        ModalConfirm({
            title: 'Impersonation Session',
            titleIcon: 'fa fa-user-secret',
            body: "Would you like to end this Impersonation Session?"
        })
        .then((confirm) => {
            if(confirm) {
                Toaster.success("Successfully teminated session")
                const originalToken = cookie.get(branding.token.name+"OG")
                const organisationIDOG = cookie.get("organisationIDOG")
                let urlOG = cookie.get("urlOG") || ""

                if(urlOG) {
                    urlOG = atob(urlOG)
                }

                if(urlOG?.includes(window.location.origin)) {
                    localStorage.setItem('selected-organisation', `${organisationIDOG}`)
                    cookie.set(branding.token.name, originalToken, { expireday: branding.token.expireday })
                    dispatch({
                        type: SessionActionType.SET_TOKEN,
                        payload: originalToken,
                    });
    
                    dispatch({
                        type: SessionActionType.SET_OTHERS,
                        payload: {
                            ...others,
                            organisationID: parseInt(organisationIDOG || "0") || others.organisationID
                        }
                    })
                } else {
                    cookie.unset(branding.token.name)
                }
                
                cookie.unset(branding.token.name+"OG")
                cookie.unset("organisationIDOG")
                cookie.unset("urlOG")
                
                window.location.assign(urlOG)
                
            }
        }) 
    }
}