
import { useQuery, useApolloClient } from '@apollo/client'
import branding from '@config/branding'
import { emptyArray } from '@whitecobalt/tungsten/esm/common/utils/assets'
import Loader from '@whitecobalt/tungsten/esm/components/Loader'
import React, { useEffect, useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { cacheQueries, EntityCachePropType, EntityCachesQuery } from './queries'

const entityCache = localStorage.getItem('entity-cache')

interface CacheGQLProps extends React.HTMLProps<HTMLButtonElement> {
    
}

const CacheGQL: React.FunctionComponent<CacheGQLProps> = ({
    children
}) => {
    const [loading, setLoading] = useState(false)
    const [numberOfDone, setNumberOfDone] = useState(0.001)
    const requestCache = useQuery<{EntityCaches: { items: EntityCachePropType[], totalCount: number; }}>(EntityCachesQuery, {
        skip: branding.services.env === 'development' || entityCache === 'off'
    })

    const client = useApolloClient()

    const cacheData = requestCache.data?.EntityCaches.items || emptyArray

    useEffect(() => {
        if(cacheData.length > 0) {
            setLoading(true)
            Promise.all(cacheData.map((each) => {
                return (cacheQueries[each.EntityName as keyof typeof cacheQueries] || []).map((query) => client.query({ 
                    query, 
                    fetchPolicy: 'cache-first'
                }).finally(() => {
                    setNumberOfDone(prev => prev + 1)
                }))
            }).flat())
            .finally(() => {
                setLoading(false)
                setNumberOfDone(cacheData.length)
            })
        }
    }, [cacheData])

    const now = cacheData.length > 0 ? Math.min(100, Math.ceil((numberOfDone/cacheData.length) * 100)) : 0

    return requestCache.loading || loading ? (
        <Loader active hasSpinner={false} className="kt-splash-screen">
				<div className="mb-4"  data-env-impose="true">
                <img src={branding.logo.splash} style={{maxWidth: 250}}/>
            </div>
            <ProgressBar variant="primary" animated now={now} label={`${now}%`} style={{width: 350}} />
        </Loader>
    ) : <>{children}</>
}

export default CacheGQL
