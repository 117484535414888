import { gql } from "@apollo/client";

export type TeamUserType = {
    ID: number;
    Notes: string;
    LanguageIDs: number[];
    IsVolunteer: boolean;
    IsCaseworkUser: boolean;
    IsCaseworkManager: boolean;
    UserID: number;
}

export const TeamUsersQuery = gql`
  query(
    $skip: Int,
    $take: Int,
    $order: [TeamUserSortInput!],
    $where: TeamUserFilterInput
  ) {
    TeamUsers(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where
    ) {
      totalCount
      items {
        ID
        Notes
        LanguageIDs
        IsVolunteer
        IsCaseworkManager
        IsCaseworkUser
        UserID
      }
    }
  }
`;