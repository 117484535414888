import { gql } from "@apollo/client";

export type DropdownQueryType<P = {
  value: number;
  label: string;
}> = {
  dropdown: {
    items: P[];
  };
}


export const FontAwesomeIconsDropdownQuery = gql`
query FontAwesomeIconsDropdownQuery($where: FontAwesomeIconFilterInput) {
    dropdown: FontAwesomeIcons(where: $where) {
      items {
        value: CSSClass
        label: Description
      }
    }
  }
`;

export const ColourPalettesDropdownQuery = gql`
query ColourPalettesDropdownQuery($where: ColourPaletteFilterInput) {
    dropdown: ColourPalettes(where: $where) {
      items {
        value: HexCode
        label: Description
      }
    }
  }
`;

export const OrganisationsDropdownQuery = gql`
query OrganisationsDropdownQuery($where: OrganisationFilterInput) {
    dropdown: Organisations(where: $where) {
      items {
        value: ID
        label: CompanyName
      }
    }
  }
`;

export const OrganisationsByTypeDropdownQuery = gql`
query OrganisationsByTypeDropdownQuery(
  $where: OrganisationFilterInput, 
  $organisationTypeID: Int!
  ) {
    dropdown: OrganisationsByType(
      where: $where, 
      OrganisationTypeID: $organisationTypeID
    ) {
      items {
        value: ID
        label: CompanyName
      }
    }
  }
`;

export const SentryProjectDropdownQuery = gql`
query SentryProjectDropdownQuery($where: SentryProjectFilterInput) {
    dropdown: SentryProjects(where: $where) {
      items {
        value: ID
        label: ProjectName
      }
    }
  }
`;

export const OrganisationTypesDropdownQuery = gql`
  query OrganisationTypesDropdownQuery{
    dropdown: OrganisationTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;


export const ParameterTypesDropdownQuery = gql`
  query ParameterTypesDropdownQuery{
    dropdown: ParameterTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const PermissionsDropdownQuery = gql`
  query PermissionsDropdownQuery($where: PermissionFilterInput) {
    dropdown: Permissions(where: $where) {
      items {
        value: ID
        label: Title
      }
    }
  }
`;

export const JobRolesDropdownQuery = gql`
  query JobRolesDropdownQuery{
    dropdown: JobRoles(order: { Description: ASC }) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UserTypesDropdownQuery = gql`
  query UserTypesDropdownQuery{
    dropdown: UserTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const CountriesDropdownQuery = gql`
  query CountriesDropdownQuery{
    dropdown: Countries {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const MobileCountriesDropdownQuery = gql`
  query MobileCountriesDropdownQuery($order: [CountrySortInput!]) {
    dropdown: Countries(order: $order) {
      items {
        value: ID
        label: PhonePrefix
      }
    }
  }
`;

export const TitlesDropdownQuery = gql`
  query TitlesDropdownQuery{
    dropdown: Titles {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const EmailTemplatesDropdownQuery = gql`
  query EmailTemplatesDropdownQuery($where: EmailTemplateFilterInput) {
    dropdown: EmailTemplates(
      where: $where
    ) {
      items {
        value: ID
        label: TemplateName
      }
    }
  }
`;


export const ApplicationAreasDropdownQuery = gql`
  query ApplicationAreasDropdownQuery{
    dropdown: ApplicationAreas(order: { Description: ASC }) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UsersDropdownQuery = gql`
  query UsersDropdownQuery($where: UserFilterInput) {
    dropdown: Users(where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const CompanyUsersDropdownQuery = gql`
  query CompanyUsersDropdownQuery($id: Int!, $where: UserFilterInput) {
    dropdown: UsersInOrganisation(iD: $id, where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const UsersByUserTypesDropdownQuery = gql`
  query UsersByUserTypesDropdownQuery($userTypeIDs: [Int!], $where: UserFilterInput) {
    dropdown: UsersByUserTypes(userTypeIDs: $userTypeIDs, where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const AccountSystemContactsDropdownQuery = gql`
  query AccountSystemContactsDropdownQuery($where: AccountSystemContactFilterInput) {  
    dropdown: AccountSystemContacts(where: $where) {
      items {
        value: ID
        label: Name
      }
    }
  }
`;

export const InvoicingModesDropdownQuery = gql`
  query InvoicingModesDropdownQuery($where: InvoicingModeFilterInput) {  
    dropdown: InvoicingModes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const BudgetAccountCodeTypesDropdownQuery = gql`
  query BudgetAccountCodeTypesDropdownQuery($where: AccountCodeTypeFilterInput) {  
    dropdown: AccountCodeTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const BudgetAccountCodesDropdownQuery = gql`
  query BudgetAccountCodesDropdownQuery($where: AccountCodeFilterInput) {  
    dropdown: AccountCodes(where: $where) {
      items {
        value: ID
        label: Description
        Code
      }
    }
  }
`;

export const BudgetVersionDropdownQuery = gql`
  query BudgetVersionDropdownQuery{  
    dropdown: BudgetVersions {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UsersInOrganisationImpersonationDropdownQuery = gql`
  query UsersInOrganisationImpersonationDropdownQuery(
    $skip: Int,
    $take: Int,
    $id: Int!,
    $where: UserFilterInput,
    $order: [UserSortInput!]
    ) {
    dropdown: UsersInOrganisation(
      skip: $skip,
      take: $take,
      iD: $id,
      where: $where,
      order: $order
    ) {
      totalCount
      items {
        value: ID
      }
    }
  }
`;

export const UsersInOrganisationDropdownQuery = gql`
  query UsersInOrganisationDropdownQuery(
    $skip: Int,
    $take: Int,
    $id: Int!,
    $where: UserFilterInput,
    $order: [UserSortInput!]
    ) {
    dropdown: UsersInOrganisation(
      skip: $skip,
      take: $take,
      iD: $id,
      where: $where,
      order: $order
    ) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const ProjectsDropdownQuery = gql`
  query ProjectsDropdownQuery($where: ProjectFilterInput) {  
    dropdown: Projects(where: $where) {
      items {
        value: ID
        label: ProjectName
      }
    }
  }
`;

export const TasksDropdownQuery = gql`
  query TasksDropdownQuery($where: TaskFilterInput) {  
    dropdown: Tasks(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;


export const TeamUsersDropdownQuery = gql`
  query($where: TeamUserFilterInput) {
    dropdown: TeamUsers(where: $where) {
      items {
        User {
          Value {
            ID
            Firstname
            Surname
          }
        }
      }
    }
  }
`;

/** Custom Start */

export const BoroughsDropdownQuery = gql`
  query {
    dropdown: BoroughNames(order: { Description: ASC }) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const OrientationsDropdownQuery = gql`
  query {
    dropdown: Orientations {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const CaseWorkCategoriesDropdownQuery = gql`
  query($order: [CaseWorkCategorySortInput!]) {
      dropdown: CaseWorkCategories(order: $order) {
        items {
          value: ID
          label: Description
        }
      }
    }
`;

export const CaseWorkStatusesDropdownQuery = gql`
  query {
    dropdown: CaseWorkStatuses {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const GoalsDropdownQuery = gql`
  query($where: GoalFilterInput) {
    dropdown: Goals(where: $where, order: { Title: ASC }) {
      items {
        value: ID
        label: Title
      }
    }
  }
`;

export const GendersDropdownQuery = gql`
  query {
    dropdown: Genders {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const ProgramTypesDropdownQuery = gql`
  query {
    dropdown: ProgramTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const RelationshipTypesDropdownQuery = gql`
  query {
    dropdown: RelationshipTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const ReligionsDropdownQuery = gql`
  query {
    dropdown: Religions {
      items {
        value: ID
        label: Description
      }
    }
  }
`;
export const IncomeSourcesDropdownQuery = gql`
  query {
    dropdown: IncomeSources {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const EmploymentStatusesDropdownQuery = gql`
  query {
    dropdown: EmploymentStatuses {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const ImmigrationStatusesDropdownQuery = gql`
  query {
    dropdown: ImmigrationStatuses {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const HousingStatusesDropdownQuery = gql`
  query {
    dropdown: HousingStatuses {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const InteractionTypesDropdownQuery = gql`
  query {
    dropdown: InteractionTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const GoalTypesDropdownQuery = gql`
  query {
    dropdown: GoalTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;
export const EthnicitiesDropdownQuery = gql`
  query {
    dropdown: Ethnicities {
      items {
        value: ID
        label: Description
      }
    }
  }
`;
export const ReferralStatusesDropdownQuery = gql`
query($where: ReferralStatusFilterInput) {
    dropdown: ReferralStatuses(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const EventTypesDropdownQuery = gql`
  query {
    dropdown: EventTypes {
      items {
        value: ID
        label: Title
        AttendingMemberIDs
        AttendingUserIDs
      }
    }
  }
`;

export const MembersDropdownQuery = gql`
  query {
    dropdown: Members(order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const AvailableTagsDropdownQuery = gql`
  query {
    dropdown: AvailableTags(order: { Tag: ASC }) {
      items {
        value: Tag
        label: Tag
      }
    }
  }
`;
export const AvailableTagGroupsDropdownQuery = gql`
  query {
    dropdown: AvailableTagGroups {
      items {
        value: ID
        label: Description
      }
    }
  }
`;
export const DocumentTypesDropdownQuery = gql`
  query {
    dropdown: DocumentTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const LanguagesDropdownQuery = gql`
  query {
    dropdown: Languages {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const DocumentClassificationsDropdownQuery = gql`
  query {
    dropdown: DocumentClassifications {
      items {
        value: ID
        label: Description
      }
    }
  }
`;
export const PriorityMemberPlanStatusesDropdownQuery = gql`
  query {
    dropdown: PriorityMemberPlanStatuss {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const PriorityMemberPlansDropdownQuery = gql`
query($where: PriorityMemberPlanFilterInput) {
  dropdown: PriorityMemberPlans(where: $where) {
      items {
        value: ID
        label: Title
      }
    }
  }
`;

export const ProgramCategoriesDropdownQuery = gql`
query{
  dropdown: ProgramCategories {
      items {
        value: ID
        label: Description
        ProgramTypeIDs
      }
    }
  }
`;

export const MemberDetailTypeCategories = gql`
query($where: MemberDetailTypeCategoryFilterInput){
    dropdown: MemberDetailTypeCategories (where: $where) {
      items {
        value: ID
        label: Description
        MemberDetailTypeID
      }
    }
  }
`;


export const InteractionsDropdownQuery = gql`
  query($where: InteractionFilterInput){
    dropdown: Interactions (where: $where) {
      items {
        value: ID
        label: Title
        MemberID
      }
    }
  }
`;

export const CaseWorkCategories = gql`
query($order: [CaseWorkCategorySortInput!]) {
  dropdown: CaseWorkCategories(order: $order) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const MemberStatusesDropdownquery = gql`
query{
  dropdown: MemberStatuses {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const BenefitsDropdownQuery = gql`
query($where: BenefitFilterInput) {
  dropdown: Benefits(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const MemberEventTypesDropdownQuery = gql`
query($where: MemberEventTypeFilterInput) {
  dropdown: MemberEventTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const OriginsDropdownQuery = gql`
query($where: OriginFilterInput) {
  dropdown: Origins(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;