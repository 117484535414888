import React from "react";
import { useLinearToken } from "@whitecobalt/tungsten/hooks/useLinearToken";
import { useReceiveUser } from "@whitecobalt/tungsten/hooks/useReceiveUser";
import { useUpdateApplication } from "@whitecobalt/tungsten/hooks/useUpdateApplication";
import { useCheckSessionID } from "@whitecobalt/tungsten/hooks/useCheckSessionID";
import { MaterialThemeProvider, MetronicLayoutProvider, MetronicSubheaderProvider } from "@metronic/layout";
import branding from "@config/branding";
import Routes from "./Routes";
import TeamUserProvider from "@components/TeamUserProvider";

export default function Application() {

	useLinearToken()
	useReceiveUser()
	useUpdateApplication(branding.services.env === 'production' ? 15 : 2)
	useCheckSessionID()

  return (
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MaterialThemeProvider>
          <TeamUserProvider>
            <Routes />
          </TeamUserProvider>
        </MaterialThemeProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  );
}
