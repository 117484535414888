import { createStore, useStore, useStoreSelectorState } from "shomai"

export interface GlobalStore {
    timeLogID: number | null;
}

export const globalStore = createStore<GlobalStore>({
    timeLogID: null
})

export const useGlobalStore = () => {
    return [useStore(globalStore, (state) => state), globalStore.setState]
}

export const useGlobalStoreSelector = <P = GlobalStore[keyof GlobalStore]>(selector: keyof GlobalStore) => useStoreSelectorState<P>(globalStore, selector)