/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { MenuListQuery } from "@services/query/MenuListQuery";
import Facade from "@whitecobalt/tungsten/Facade"
import Collapse from "@whitecobalt/tungsten/Collapse"
import { useQuery } from "@apollo/client";
import classNames from "classnames";
import { emptyArray } from "@whitecobalt/tungsten/utils/assets";
import { sortData, SortOrder } from "@whitecobalt/tungsten/utils/data";
import branding from "@config/branding";
import { SessionActionType, useSession } from "@whitecobalt/tungsten/esm/components/Session";

export function AsideMenuList({ layoutProps }) {
  const [, dispatch] = useSession()
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const requestMenu = useQuery(MenuListQuery, {
    fetchPolicy: "no-cache",
    variables: { MenuID: 1 },
  });

  useEffect(() => {
    dispatch({
      type: SessionActionType.UPDATE_OTHERS,
      payload: {
        menuListLoaded: !requestMenu.loading
      }
    })
  }, [requestMenu.loading])

  const data = requestMenu.data?.MenuItems?.items || emptyArray

  const findIndex = (Array, value)=> {
    const index = Array?.findIndex((each)=> each.subMenuList.find(item=> item?.ID === value))
    return index
  }

  const menuList = useMemo(() => {
    const sorted = sortData({ order: SortOrder.ASC, orderBy: 'ID'}, data)
    const reducedData = sorted?.reduce((result, each) => {
    
      if((each.IsNavMenu || (!each.IsNavMenu && !!each.ParentMenuItemID) ) && !!result.find((header) => header.ID === each.ParentMenuItemID) /*&& each.Title !== 'Widgets'*/) {
        result.find((header) => header.ID === each.ParentMenuItemID)?.subMenuList.push({ ...each, subMenuList:[]})
      }
      else {
        const MainMenuIndex = findIndex(result,each?.ParentMenuItemID);
        if(MainMenuIndex >= 0){
          result[MainMenuIndex].subMenuList.find((header) => header.ID === each.ParentMenuItemID)?.subMenuList.push({ ...each, subMenuList:[]})
        } 
      }
      return result
    }, sorted?.filter((each) => !each.ParentMenuItemID /*&& each.Title !== 'Dashboard'*/)
    .map((each) => ({...each, subMenuList: []})))
    .filter(each => !!each.URL || each.subMenuList.length > 0)

    return sortData({ order: SortOrder.ASC, orderBy: 'ListOrder'}, reducedData)
  }, [data])

  function flat(array) {
      var result = [];
      array.forEach(function (a) {
          if(Array.isArray(a)){
            a.forEach(item => result.push(item))
          } else {
            result.push(a);
          }
      });
      return result;
  }

  return requestMenu.loading ? 
    new Array(3).fill(0).map((_, index) => (
      <ul key={index} className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className="menu-section ">
          <Facade loading title titleStyle={{height: 13, marginTop: 16}} padding={false} rows={0}/>
        </li>
        {new Array(3).fill(0).map((_, index) => (
          <li key={index}
              className={`menu-item `}
              aria-haspopup="true"
            >
            <a className="menu-link" href="#">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text"><Facade loading rowListStyle={{margin: 0}} range={[40, 70]} rowStyle={{height: 13}} padding={false} rows={1}/></span>
            </a>
          </li>
        ))}
      </ul>
    )) 
  : menuList.map((each) => {
      const SecondLevelMenuList = [...each.subMenuList.map(item=>item)] || [];
      const ThirdLevelMenuList = flat(SecondLevelMenuList.map(item=>item.subMenuList)) || [];
      const hasActive = [...ThirdLevelMenuList.map(item => item?.URL), ...SecondLevelMenuList.filter(i => !!i.URL).map(item => item?.URL)].some((each) => (location.pathname + (each.includes('?') ? location.search : '')).startsWith(each))

      const LinkComponent = each.LaunchExternal ? 'a' : NavLink
      return (
        <ul key={each.ID} className={`menu-nav ${layoutProps.ulClasses}`}>
          {!!each.URL ? (
            <li className={classNames("menu-section", {"menu-section-active": location.pathname.startsWith(each.URL)})}>
                <LinkComponent className="menu-link d-flex w-100 h-100" to={each.URL || ""}>
                  <div className="d-flex">
                    <h4 className="menu-text">{each.Title}</h4>
                  </div>
                </LinkComponent>
            </li>
          ) : (
            <>
              <Collapse.Target as="li" eventKey={each.Title} className={classNames("menu-section", {"menu-section-active": hasActive})}>
                <div className="d-flex">
                  <h4 className="menu-text">{each.Title}</h4>
                </div>
                <i className="menu-icon fa fa-angle-right" aria-hidden="true"/>
              </Collapse.Target>
              <Collapse show={hasActive} persistentShow={hasActive} eventKey={each.Title} transition="fast" accordionKey="aside-menu">
                {each.subMenuList.map((each) => {
                  const LinkComponent = each.LaunchExternal ? 'a' : NavLink
                 const ThirdLevelActive = [...each.subMenuList.map(item=>item)].map(item => item?.URL).filter(each => !!each).some((each) => (location.pathname + (each.includes('?') ? location.search : '')).startsWith(each))
                  return (
                      <ul key={each.ID} className={`menu-nav second-level ${layoutProps.ulClasses} `} >
                          {!!each.URL ? (
                            <li className={classNames("menu-section", {"menu-section-active": location.pathname.startsWith(each.URL)})}>
                                <LinkComponent className="menu-link d-flex w-100 h-100" to={each.URL || ""}>
                                  <div className="d-flex ml-5">
                                    <h4 className="menu-text">{each.Title}</h4>
                                  </div>
                                </LinkComponent>
                            </li>
                          ) : (
                            <>
                              <Collapse.Target as="li" eventKey={each.Title} className={classNames("menu-section", {"menu-section-active": ThirdLevelActive})}>
                                <div className={classNames("d-flex",{"ml-5": !!each.subMenuList.length})}>
                                  <h4 className="menu-text">
                                      {each.Title}
                                  </h4>
                                </div>
                                <i className="menu-icon fa fa-angle-right" aria-hidden="true"/>
                              </Collapse.Target>
                              <Collapse show={ThirdLevelActive} persistentShow={ThirdLevelActive} eventKey={each.Title} transition="fast" accordionKey="aside-menu2">
                                {each.subMenuList.map((each) => {
                                  const LinkComponent = each.LaunchExternal ? 'a' : NavLink
                                  return (
                                    <li key={each.ID}
                                        className={`menu-item ${getMenuItemActive(each.URL || "", false)}`}
                                        aria-haspopup="true"
                                      >
                                      <LinkComponent className="menu-link" to={each.URL || ""}>
                                        <span className="svg-icon menu-icon sub-menu-icon ml-5">
                                          <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                                        </span>
                                        <span className="menu-text">{each.Title}</span>
                                      </LinkComponent>
                                    </li>
                                  )
                              })}
                              </Collapse>
                            </>
                          )}
                        </ul>
                    // <li key={each.ID}
                    //     className={`menu-item ${getMenuItemActive(each.URL || "", false)}`}
                    //     aria-haspopup="true"
                    //   >
                    //   <LinkComponent className="menu-link" to={each.URL || ""}>
                    //     <span className="svg-icon menu-icon sub-menu-icon">
                    //       <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                    //     </span>
                    //     <span className="menu-text">{each.Title}</span>
                    //   </LinkComponent>
                    // </li>
                  )
              })}
              </Collapse>
            </>
          )}
        </ul>
      )
    })
}
