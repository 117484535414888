import { useLazyQuery } from '@apollo/client'
import branding from '@config/branding'
import { TeamUsersQuery, TeamUserType } from '@services/query/TeamUserQuery'
import Loader from '@whitecobalt/tungsten/Loader'
import { useSession } from '@whitecobalt/tungsten/Session'
import React, { createContext, useContext, useEffect, useState } from 'react'
import Spinner from "react-bootstrap/Spinner"

const TeamUserContext = createContext<{data: Partial<TeamUserType>, refetch: () => void;}>(null as any)

export const useTeamUser = () => useContext(TeamUserContext)

interface TeamUserProviderProps {
    
}

const TeamUserProvider: React.FunctionComponent<TeamUserProviderProps> = ({children}) => {
    const [{user}] = useSession()
    const [loading, setLoading] = useState(true);
    const [load, request] = useLazyQuery<{ TeamUsers: { items?: TeamUserType[]}}>(TeamUsersQuery, {
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            setLoading(false)
        }
    })

    useEffect(() => {
        if(user?.id) {
            load({
                variables: {
                    where: {
                        UserID: {
                            equals: user?.id
                        }
                    }
                }
            })
        }
    }, [user])

    // useEffect(() => {
    //     if((request.data?.TeamUsers?.items || []).length > 0) {
    //         const cleanup = setInterval(() => (request as any)?.refetch(), 60000)
    
    //         return () => clearInterval(cleanup)
    //     }
    // }, [request.data])

    if(loading && !!user) {
		return (
			<Loader active hasSpinner={false} className="kt-splash-screen">
                <div className="mb-4" data-env-impose="true">
				    <img className="mb-4" src={branding.logo.splash} style={{maxWidth: 350}}/>
                </div>
				<Spinner animation="border" />
			</Loader>
		)
    }
    
    const teamUserData = (request.data?.TeamUsers?.items || [])[0] || {}
    
    return (
        <TeamUserContext.Provider value={{data: teamUserData, refetch: () => (request as any)?.refetch()}}>
            {children}
        </TeamUserContext.Provider>
    )
}

export default TeamUserProvider
