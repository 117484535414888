import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import ModalForm from "@whitecobalt/tungsten/Modal/Form";
import FormManager from "@whitecobalt/tungsten/FormManager";
import { useAuthAPI } from "@whitecobalt/tungsten/hooks/useAuthAPI";
import { useAuthParamAPI } from "@whitecobalt/tungsten/hooks/useAuthParamAPI";
import Toaster from "@whitecobalt/tungsten/Toaster";
import { SessionActionType, useSession } from "@whitecobalt/tungsten/Session";
import OTP from "@whitecobalt/tungsten/Fields/OTP";
import Spinner from 'react-bootstrap/Spinner';
import PasswordField from '@whitecobalt/tungsten/esm/components/Fields/PasswordField';
import branding from '@config/branding';

const MFAActivation: React.FunctionComponent = () => {
    const requestEnableMFA = useAuthAPI('/api/EnableMFA')
	const requestDisableMFA = useAuthAPI('/api/DisableMFA')
	const requestVerifyEnableMFA = useAuthParamAPI('/api/VerifyEnableMFA?MFACode={code}', { method: 'POST' })
	const [{user}, dispatch] = useSession()
	const [isEnableMFA, setIsEnableMFA] = useState(user?.loginMFAEnabled);

	useEffect(() => {
		setIsEnableMFA(user?.loginMFAEnabled)
	}, [user?.loginMFAEnabled])

	const handleToggleMFA = (event: any) => {
		const isEnabled = event.target.checked

		if (isEnabled) {
			const toast = Toaster('Enabling MFA')
			requestEnableMFA.call({
				data: {
					applicationAreaID: 1
				}
			})
			.then((response) => {
				if(response.data.success) {
					handleEnableMFA()
					toast.success(response.data.message)
				} else {
					toast.fail(response.data.message)
				}
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message || branding.messages.error
				toast.error(errorMessage)
			})
		} else {
			handleDisableMFA()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}

	const handleDisableMFA = () => {
		const recursiveModal = () => {
			ModalForm({
				key: 'disable-mfa',
				title: 'Disable MFA',
				titleIcon: 'fa fa-sms',
				submit: 'Disable MFA',
				body: (
				<>
					<p className="mb-5 text-center">Please enter your Password to Disable MFA</p>
					<FormManager.Input as={PasswordField} name="password" placeholder="Enter your password" formGroupClassName="mb-0"/>
				</>
				)
			})
			.then((event) => {
				if(event.isSubmit) {
					const FORM_DATA = event.json()
					const toast = Toaster('Disabling MFA')

					requestDisableMFA.call({
						data: {
							password: FORM_DATA.password
						}
					})
					.then((response) => {
						if(response.data.success) {
							event.closeModal()
							updateUser()
							toast.success('Successfully disabled MFA')
						} else {

							toast.fail()

							event.setFieldError('password', response.data.message)

							recursiveModal()
						}
					})
					.catch((error) => {
                        const errorMessage = error?.response?.data?.message || branding.messages.error
						toast.error(errorMessage)
						event.setError(errorMessage)
						recursiveModal()
					})
					.finally(() => {
						event.removeLoading()
					})
				}
			})
		}

		recursiveModal()
	}

	const handleEnableMFA = () => {
		const recursiveModal = () => {
			ModalForm({
				key: 'one-time-pass',
				title: 'MFA Verification',
				titleIcon: 'fa fa-sms',
				submit: 'Verify',
				body: (
				<>
					<h3 className="text-center">One-Time Password</h3>
					<p className="mb-5 text-center">Please enter the code send to your phone by SMS</p>
					<FormManager.Input name="code" as={OTP} codeLength={6} formGroupClassName="mb-0" />
				</>
				)
			})
			.then((event) => {
				if(event.isSubmit) {
					const FORM_DATA = event.json()
					const toast = Toaster('Enabling MFA')

					requestVerifyEnableMFA.call({ 
						code: FORM_DATA.code
					})
					.then((response) => {
						if(response.data.success) {
							event.removeLoading()
							event.closeModal()
							updateUser()
							toast.success('Successfully enabled MFA')
						} else {
							event.removeLoading()

							toast.fail()

							event.setFieldError('code', response.data.message)

							recursiveModal()
						}
					})
					.catch((error) => {
                        const errorMessage = error?.response?.data?.message || branding.messages.error
						toast.error(errorMessage)
						event.setError(errorMessage)
					})
				}
			})
		}

		recursiveModal()
	}

	const updateUser = () => {
		dispatch({ 
            type: SessionActionType.SET_USER,
            payload: {
                ...user,
                loginMFAEnabled: !user?.loginMFAEnabled
            }
        })
    }
    
    return (
        <Form.Group className="d-flex align-items-center">
			<Form.Check 
				type="switch"
				id="mfa-switch"
				checked={isEnableMFA}
				label="Enable MFA"
				disabled={requestEnableMFA.loading}
				onChange={handleToggleMFA}/>
        </Form.Group>
    )
}

export default MFAActivation