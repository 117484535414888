export default {
    logo: {
        auth: '/media/logos/logo-default.png',
        splash: '/media/logos/logo-default.png',
        navbar: '/media/logos/logo-default.png',
    },
    background: '/media/bg/sc-home1-bg.png',
    brandName: 'Body & Soul',
    brandSite: 'http://bodyandsoulcharity.org/',
    brandingFooter: true,
    allowOrganisationSelection: false,
    allowImpersonation: true,
    timeTracking: false,
    dateFormatDayLightSaving: false,
    landingPath: '/dashboard',
    color: {
        primary: '#7E8299',
        secondary: '#7E8299',
        loginSide: '#f483b6'
    },
    applicationAreaID: 1,
    getAddressKey: process.env.REACT_APP_GET_ADDRESS_API_KEY,
    services: {
        endpoint: process.env.REACT_APP_DATA_API_URL!,
        gql: process.env.REACT_APP_GRAPH_API_URL!,
        env: process.env.REACT_APP_ENV!,
        onesignal: process.env.REACT_APP_ONE_SIGNAL_ID!,
        getaddress: process.env.REACT_APP_GET_ADDRESS_API_KEY,
    },
    token: {
        name: 'bodySoulADTK',
        expireday: 1, // 1 day
        domain: '',
        expireMessage: 'To protect your data, you have been logged out. Please login again to continue.'
    },
    notification: {
        soundEffectEnable: true,
        pathSoundEffect: '/media/audio/mixkit-attention-bell-ding.wav'
    },
    toaster: {
        soundEffectEnable: false,
        pathSoundEffectIn: '/media/audio/mixkit-message-pop-alert.mp3',
        pathSoundEffectOut: '/media/audio/mixkit-long-pop.wav'
    },
    messages: {
        simultaneousPreventionMessage: "You have been logged out because your User Account has logged in elsewhere.",
        error: process.env.REACT_APP_ENV === 'production' ? 'Something went wrong.' : 'There is an issue with the endpoint.',
        fail: 'There are errors with your entry. Please check and try again.'
    }, 
    menuIcons: {
    },
    menuPopups: {
    },
    filterGQLError: (count: number, error: any, operation: any) => {
        return false
    }
}