import { gql } from "@apollo/client";

export type NotificationsPropType = {
    ID: number;
    UserID: number;
    DateTimeStamp: string;
    Title: string;
    Description: string;
    IsRead: boolean;
    Link: string;
}

export type NotificationsQueryType = {
    Notifications: {
        items: NotificationsPropType[];
        totalCount: number;
    }
}

export type NotificationsUpdateQueryType = {
    updates: {
        items: NotificationsPropType[];
        totalCount: number;
    }
}

export const NotificationsQuery = gql`
query(
    $take: Int,
    $skip: Int,
    $order: [NotificationSortInput!],
    $where: NotificationFilterInput
  ) {
    Notifications (
      take: $take,
      skip: $skip,
      order: $order,
      where: $where
    ) {
      totalCount
      items {
        ID
        UserID
        DateTimeStamp
        Title
        Description
        IsRead
        Link
      }
    }
  }
`
export const NotificationsUpdateQuery = gql`
query(
    $take: Int,
    $skip: Int,
    $order: [NotificationSortInput!],
    $where: NotificationFilterInput
  ) {
    updates: Notifications (
      take: $take,
      skip: $skip,
      order: $order,
      where: $where,
    ) {
      totalCount
      items {
        ID
        UserID
        DateTimeStamp
        Title
        Description
        IsRead
        Link
      }
    }
  }
`