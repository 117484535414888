import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import ModalForm from "@whitecobalt/tungsten/Modal/Form";
import FormManager from "@whitecobalt/tungsten/FormManager";
import { useAuthAPI } from "@whitecobalt/tungsten/hooks/useAuthAPI";
import Toaster from "@whitecobalt/tungsten/Toaster";
import { SessionActionType, useSession } from "@whitecobalt/tungsten/Session";
import OTP from "@whitecobalt/tungsten/Fields/OTP";
import Spinner from 'react-bootstrap/Spinner';
import branding from '@config/branding';
import ModalConfirm from '@whitecobalt/tungsten/esm/components/Modal/Confirm';

const TOTPGoogleAuth: React.FunctionComponent = () => {
    const requestGoogleAuth = useAuthAPI('/api/GoogleAuth/enabled', { method: "GET" })
    const requestEnableTOTP = useAuthAPI('/api/GoogleAuth/setup')
	const requestVerifyActivation = useAuthAPI('/api/GoogleAuth/activate')
	const requestDeactivate = useAuthAPI('/api/GoogleAuth/deactivate')
	const [{user}, dispatch] = useSession()
	const [isEnableMFA, setIsEnableMFA] = useState(user?.googleAuthenticatorActive);

    useEffect(() => {
        requestGoogleAuth.call()
    }, [])

	useEffect(() => {
		setIsEnableMFA(user?.googleAuthenticatorActive)
	}, [user?.googleAuthenticatorActive])

	const handleToggleMFA = (event: any) => {
		const isEnabled = event.target.checked

		if (isEnabled) {
			const toast = Toaster('Enabling Google Authenticator')
			requestEnableTOTP.call()
			.then((response) => {
				if(response.data.success) {
					handleEnableTOTP(response.data.qrCodeURL)
					toast.void()
				} else {
					toast.fail(response.data.message)
				}
			})
			.catch((error) => {
                const errorMessage = error?.response?.data?.message || branding.messages.error
				toast.error(errorMessage)
			})
		} else {
			handleDisableTOTP()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}

	const handleDisableTOTP = () => {
		const recursiveModal = () => {
			ModalConfirm({
				title: 'Disable Google Authenticator',
				titleIcon: 'fa fa-mobile',
				submit: 'Yes - Disable',
				body: (
				<>
					<p className="mb-5">You are Disabling Google Authenticator as your Two-Factor Authentication.</p>
                    <span>Are you sure?</span>
                </>
				)
			})
			.then((confirm) => {
				if(confirm) {
					const toast = Toaster('Disabling Google Authenticator')

					requestDeactivate.call()
					.then((response) => {
						if(response.data.success) {
                            updateUser()
							toast.success(response.data.message || 'Successfully disabled Google Authenticator')
						} else {

							toast.fail()
						}
					})
					.catch((error) => {
                        const errorMessage = error?.response?.data?.message || branding.messages.error
						toast.error(errorMessage)
					})
				}
			})
		}

		recursiveModal()
	}

	const handleEnableTOTP = (qrcode: string) => {
		const recursiveModal = () => {
			ModalForm({
				key: 'one-time-pass',
				title: 'Google Authenticator Verification',
				titleIcon: 'fa fa-mobile',
				submit: 'Verify',
				body: (
				<>
                    <p className="mb-5">Each time you log in, in addition to your password, you'll use google authenticator app to generate a one-time code.</p>
                    <h5 className="mb-5">Step 1: Scan the QR code below.</h5>
                    <div className="d-flex justify-content-center">
                        <img className="img-thumbnail" src={qrcode} />
                    </div>
                    <hr/>
                    <br/>
                    <div className="d-flex" style={{lineHeight: 1.5}}>
                        <h5 className="mb-5 mr-2" style={{whiteSpace: 'nowrap'}}>Step 2:</h5>
                        <h5 className="mb-5">Enter the 6-digit code you see in your google authenticator app.</h5>
                    </div>
					<FormManager.Input name="code" as={OTP} codeLength={6} formGroupClassName="mb-0" />
				</>
				)
			})
			.then((event) => {
				if(event.isSubmit) {
					const FORM_DATA = event.json()
					const toast = Toaster('Verifying Google Authenticator')

					requestVerifyActivation.call({ 
						data: {
                            googleAuthCode: FORM_DATA.code
                        }
					})
					.then((response) => {
						if(response.data.success) {
							event.removeLoading()
							event.closeModal()
							updateUser()
							toast.success('Successfully verified Google Authenticator')
						} else {
							event.removeLoading()

							toast.fail()

							event.setFieldError('code', response.data.message)

							recursiveModal()
						}
					})
					.catch((error) => {
                        const errorMessage = error?.response?.data?.message || branding.messages.error
						toast.error(errorMessage)
						event.setError(errorMessage)
					})
				}
			})
		}

		recursiveModal()
	}

	const updateUser = () => {
		dispatch({ 
            type: SessionActionType.SET_USER,
            payload: {
                ...user,
                googleAuthenticatorActive: !user?.googleAuthenticatorActive
            }
        })
    }
    
    return requestGoogleAuth.loading || requestGoogleAuth.response ? (
        <Form.Group className="d-flex align-items-center">
			<Form.Check 
				type="switch"
				id="totp-switch"
				checked={isEnableMFA}
				label="Enable Google Authenticator"
				disabled={requestEnableTOTP.loading}
				onChange={handleToggleMFA}/>
        </Form.Group>
    ) : null
}

export default TOTPGoogleAuth