import FormManager from '@whitecobalt/tungsten/FormManager'
import React, { useMemo, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import DatePicker from '@whitecobalt/tungsten/Fields/Date'
import CheckList from '@whitecobalt/tungsten/Fields/CheckList'
import { BoroughsDropdownQuery, CountriesDropdownQuery, DropdownQueryType, GendersDropdownQuery, LanguagesDropdownQuery, MobileCountriesDropdownQuery, OrientationsDropdownQuery, ProgramCategoriesDropdownQuery, ProgramTypesDropdownQuery, ReligionsDropdownQuery } from '@services/query/DropdownQueries'
import { FormEvent, useForm } from 'formydable/esm'
import { EthnicitiesDropdownQuery } from '../../../services/query/DropdownQueries'
import SelectDropdown from '@whitecobalt/tungsten/Fields/SelectDropdown'
import differenceInYears from 'date-fns/differenceInYears'
import { useAuthAPI } from '@whitecobalt/tungsten/hooks/useAuthAPI'
import Icon from '@whitecobalt/tungsten/Icon'
import ModalView from '@whitecobalt/tungsten/Modal/View'
import DisconnectedJobLoader from '@whitecobalt/tungsten/DisconnectedJobLoader'
import Button from '@whitecobalt/tungsten/Button'
import imageMember from '@images/becoming-a-member-professional-referral.png'
import { useScreenTitle } from '@whitecobalt/tungsten/hooks/useScreenTitle'
import ChildrenFieldGrid from './ChildrenFieldGrid'
import AutoComplete from '@whitecobalt/tungsten/Fields/AutoComplete'
import './index.scss'
import { useQuery } from '@apollo/client'
import { dateLocalUTC } from '@whitecobalt/tungsten/esm/common/utils/dateFormat'
interface FinalAssessmentProps {
    
}

const FinalAssessment: React.FunctionComponent<FinalAssessmentProps> = (props) => {
    useScreenTitle('Body & Soul - Become a member')
    const [referenceNumber, setReferenceNumber] = useState<any>(null)
    const requestReferralForm = useAuthAPI('/api/Referral/web')
    const forms = useForm()
    const birthDate = forms.formState('dateOfBirth').value || new Date()
    const age = differenceInYears(new Date(), birthDate);
    const programID = forms?.getValue("programTypeID");
    const requestCategories = useQuery<DropdownQueryType>(ProgramCategoriesDropdownQuery)
    const categoriesOptions = !!programID  && !requestCategories.loading ? requestCategories.data?.dropdown.items.filter((item : any) =>{return item?.ProgramTypeIDs && item?.ProgramTypeIDs?.includes(programID)}) : []
    const handleSubmit = (event: FormEvent) => {
        if(event.isReady()) {

            const FORM_DATA = event.json()

            const payload = {
                "referral": {
                  "id": 0,
                  "referrerName": FORM_DATA.referrerName,
                  "referrerContactNumber": FORM_DATA.referrerContactNumber,
                  "referrerContactEmailAddress": FORM_DATA.referrerContactEmailAddress,
                  "referrerNotes": FORM_DATA.referrerNotes,
                  "firstname": FORM_DATA.firstname,
                  "surname": FORM_DATA.surname,
                  "dateOfBirth": dateLocalUTC(FORM_DATA.dateOfBirth) || undefined,
                  "emailAddress": FORM_DATA.emailAddress,
                  "mobileNumber": FORM_DATA.mobileNumber,
                  "mobileCountryID": FORM_DATA.mobileCountryID,
                  "homeNumber": FORM_DATA.homeNumber,
                  "homeAddress": FORM_DATA.homeAddress,
                  "genderID": FORM_DATA.genderID || null,
                  "orientationID": FORM_DATA.orientationID || null,
                  "postcode": FORM_DATA.postcode,
                  "boroughNameID": FORM_DATA.boroughNameID,
                  "livingWithDetail": FORM_DATA.livingWithDetail,
                  "parentGuardian": FORM_DATA.parentGuardian,
                  "parentGuardianMobileNumber": FORM_DATA.parentGuardianMobileNumber,
                  "emergencyContactName": FORM_DATA.emergencyContactName,
                  "emergencyContactNumber": FORM_DATA.emergencyContactNumber,
                  "contactViaEmail": FORM_DATA.contactViaEmail === true,
                  "contactViaPost": FORM_DATA.contactViaPost === true,
                  "contactViaPhone": FORM_DATA.contactViaPhoneCheckbox === true,
                  "contactViaText": FORM_DATA.contactViaText === true,
                  "ethnicityID": FORM_DATA.ethnicityID || null,
                  "ethnicityOther": FORM_DATA.ethnicityOther,
                  "religionID": FORM_DATA.religionID || null,
                  "hasDisability": FORM_DATA.hasDisability === 'Yes',
                  "countryOriginID": FORM_DATA.countryOriginID || 0,
                  "countryBirthID": FORM_DATA.countryOriginID || 0,
                  "languageIDs": FORM_DATA.languageIDs || [],
                  "gpName": FORM_DATA.gpName,
                  "gpSurgery": FORM_DATA.gpSurgery,
                  "consultantNa": FORM_DATA.consultantNa,
                  "hospital": FORM_DATA.hospital,
                  "nurseSpecialist": FORM_DATA.nurseSpecialist,
                  "mentalHealthProfessionals": FORM_DATA.mentalHealthProfessionals,
                  "socialWorkerName": FORM_DATA.socialWorkerName,
                  "otherProfessionals": FORM_DATA.otherProfessionals,
                  "recentHealthServiceDetail": FORM_DATA.recentHealthServiceDetail,
                  "isTakingPrescribedMedication": FORM_DATA.isTakingPrescribedMedication === 'Yes',
                  "prescribedMedicationDetail": FORM_DATA.prescribedMedicationDetail,
                  "hasUsedDrugs": FORM_DATA.hasUsedDrugs === 'Yes',
                  "drugsUsedDetail": FORM_DATA.drugsUsedDetail,
                  "hasUsedAlcohol": FORM_DATA.hasUsedAlcohol === 'Yes',
                  "alcoholUseFrequencyDetail": FORM_DATA.alcoholUseFrequencyDetail,
                  "sleepDetail": FORM_DATA.sleepDetail,
                  "eatingDetail": FORM_DATA.eatingDetail,
                  "isEnvironmentSafe": FORM_DATA.isEnvironmentSafe === 'Yes',
                  "environmentSafetyDetail": FORM_DATA.environmentSafetyDetail,
                  "environmentContextDetail": FORM_DATA.environmentContextDetail,
                  "wellnessSupportDetail": FORM_DATA.wellnessSupportDetail,
                  "hasAttemptedSuicide": FORM_DATA.hasAttemptedSuicide === 'Yes',
                  "lastSuicideAttemptDetail": FORM_DATA.lastSuicideAttemptDetail,
                  "lastSuicideAttemptSituationDetail": FORM_DATA.lastSuicideAttemptSituationDetail,
                  "lastSuicideAttemptInterruptionDetail": FORM_DATA.lastSuicideAttemptInterruptionDetail,
                  "lastSuicideAttemptKnowledgeDetail": FORM_DATA.lastSuicideAttemptKnowledgeDetail,
                  "previousSuicideAttemptsDetail": FORM_DATA.previousSuicideAttemptsDetail,
                  "hasInflictedSelfHarm": FORM_DATA.hasInflictedSelfHarm === 'Yes',
                  "selfHarmDetail": FORM_DATA.selfHarmDetail,
                  "protectiveFactorsDetail": FORM_DATA.protectiveFactorsDetail,
                  "supportNeeded": FORM_DATA.supportNeeded,
                  "strengthsLikesInterests": FORM_DATA.strengthsLikesInterests,
                  "familyAccess": FORM_DATA.familyAccess,
                  "accessNeeds": FORM_DATA.accessNeeds,
                  "assessmentCompletedBy": FORM_DATA.assessmentCompletedBy,
                  "assessmentDate": FORM_DATA.assessmentDate || undefined,
                  "triageAttendance": FORM_DATA.triageAttendance,
                  "triageDate": FORM_DATA.triageDate || undefined,
                  "triageNotes": FORM_DATA.triageNotes,
                  "engagementPlan": FORM_DATA.engagementPlan
                },
                "memberProgram": {
                    "id": 0,
                    "memberID": 0,
                    "programTypeID": FORM_DATA?.programTypeID,
                    "programCategoryID":  FORM_DATA?.programTypeID,
                    "remarks":  FORM_DATA?.remarks,
                },
                "children": FORM_DATA.children ? FORM_DATA.children.map(({dateOfBirth, isAwareOfBodySoul, isLivingWithYou, willAccessBodySoul, ...props}: any) => {
                    return {
                        dateOfBirth: dateOfBirth || undefined,
                        isAwareOfBodySoul: isAwareOfBodySoul === true,
                        isLivingWithYou: isLivingWithYou === true,
                        willAccessBodySoul: willAccessBodySoul === true,
                        ...props
                    }
                }) : [],
                "health": FORM_DATA.health ? FORM_DATA.health.map(({onsetDate, ...props}: any) => {
                    return {
                        onsetDate: onsetDate || undefined,
                        ...props
                    }
                }) : [],
            }

            requestReferralForm.call({
                data: payload
            })
            .then((response) => {
                if(response.data.success) {
                    event.resetForm()
                    setReferenceNumber(response.data.uniqueReference)
                } else {
                    ModalView({
                        title: '',
                        centered: true,
                        className: 'modal-message',
                        body: (
                            <>
                                <p className="text-center text-warning"><Icon size={60} name="exclamation-circle"/></p>
                                <h3 className="text-center mb-5">{response.data.message || 'There are invalid inputs.'}</h3>
                                <div className="alert alert-danger">
                                    <ul className="m-0 pl-3">
                                        {response.data.errors.map((error: any) => {
                                            return <li className="mb-2">{error.errorMessage}</li>
                                        })}
                                    </ul>
                                </div>
                            </>
                        )
                    })

                    FormManager.setBackendValidation(event, response.data.errors, {
                       'EmailAddress': 'emailAddress',
                    })

                    setTimeout(() => {
                        const scrollableElement = document.documentElement;
                        const lowestErrorTop = Array.from(document.querySelectorAll('.is-invalid')).reduce((result: any, error: any) => {
                            const currentTop = scrollableElement.scrollTop + (error && error.getBoundingClientRect().top || 0);
                            return result ? Math.min(currentTop, result) : currentTop;
                        }, null);
                        
                        scrollableElement.scrollTo({
                            top: lowestErrorTop - 300,
                            left: 0,
                            behavior: 'smooth'
                        })

                    }, 500)
                }
            })
        }
    }

    return (
        <div className="referral-form">
            <div className="referral-form-header">
                {referenceNumber ? (
                    <h1 className="thank-you-sign">Thank You!</h1>
                ) : (
                    <img 
                        className="become-member" 
                        src={imageMember} 
                        alt="" height="1666" width="3675"/>
                )}
            </div>
            <div className="divider"/>
                {referenceNumber ? (
                    <div className="mt-6">
                        <h2 className="referral-form-title text-center">A member of the Body & Soul team will be in touch with you soon.</h2>
                        <p className="text-center">This is your reference number for your referral <br/><b>{referenceNumber}</b></p>
                    </div>
                ) : (
                    <FormManager.Core value={forms} onSubmit={handleSubmit}>
                        <FormManager.Boolean className="mt-10" name="profesionalReferral" label="I am completing this form on behalf of someone else"/>
                        <FormManager.Condition name="profesionalReferral" showWhen={(value) => value}>
                            <div className="mt-6">
                                <h3 className="referral-form-title">Referrer Details</h3>
                                <Row>
                                    <Col sm={6}>
                                        <FormManager.Input 
                                            label="Name:" 
                                            name="referrerName" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <FormManager.Input 
                                            label="Contact Number:" 
                                            name="referrerContactNumber" />
                                    </Col>
                                    <Col sm={6}>
                                        <FormManager.Input 
                                            label="Email address:" 
                                            name="referrerContactEmailAddress" />
                                    </Col>
                                </Row>
                                <FormManager.Input 
                                    type="textarea"
                                    rows={5}
                                    label="Notes:" 
                                    name="referrerNotes"/>
                            </div>
                        </FormManager.Condition>
                        <div className="mt-6">
                            <h3 className="referral-form-title">Personal Details for the individual being referred</h3>
                            <Row>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        required
                                        label="First Name:" 
                                        name="firstname" />
                                </Col>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        required
                                        label="Last Name:" 
                                        name="surname" />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <FormManager.Input
                                        required
                                        as={DatePicker}
                                        label="Date of Birth:" 
                                        name="dateOfBirth"
                                        showYearDropdown
                                            />
                                </Col>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        required
                                        as={SelectDropdown.Graph}
                                        gql={EthnicitiesDropdownQuery}
                                        label="Ethnicity:" 
                                        name="ethnicityID"
                                        clearable={false} />
                                </Col>
                            </Row>
                            <FormManager.Condition name="ethnicityID" showWhen={(value) => [6, 10, 17, 21].includes(value)}>
                                <FormManager.Input 
                                    label="Other Ethnicity:" 
                                    name="ethnicityOther" />
                            </FormManager.Condition>
                            <Row>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        required
                                        as={SelectDropdown.Graph}
                                        gql={ReligionsDropdownQuery}
                                        label="Religion or non-religious beliefs:" 
                                        name="religionID"
                                        clearable={false} />
                                </Col>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        required
                                        as={SelectDropdown.Graph}
                                        gql={OrientationsDropdownQuery}
                                        label="Sexual Orientation:" 
                                        name="orientationID"
                                        clearable={false} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <FormManager.Input
                                        required
                                        as={CheckList.Graph}
                                        gql={GendersDropdownQuery}
                                        type="radio"
                                        inline
                                        label="Gender:" 
                                        name="genderID" />
                                </Col>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        as={AutoComplete.Graph}
                                        gql={LanguagesDropdownQuery}
                                        multiple
                                        label="Languages spoken:" 
                                        name="languageIDs" />
                                    {/* <p>(Please note that in order to engage with the programmes we run, members are required to hold a conversational English).</p> */}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        name="hasDisability"
                                        label="Do you consider yourself to have a disability?"
                                        as={CheckList}
                                        options={[
                                            {
                                                label: 'Yes',
                                                value: 'Yes'
                                            },
                                            {
                                                label: 'No',
                                                value: 'No'
                                            },
                                        ]}
                                        type="radio"
                                        inline />
                                </Col>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        required
                                        as={SelectDropdown.Graph}
                                        gql={CountriesDropdownQuery}
                                        label="Country of Birth:" 
                                        name="countryOriginID"
                                        value={1} />
                                </Col>
                            </Row>
                            <FormManager.Input 
                                required
                                label="Address:" 
                                name="homeAddress" />
                            <Row>
                                <Col sm={6}>
                                    {/* <FormManager.Input 
                                        as={SelectDropdown.Graph}
                                        gql={BoroughsDropdownQuery}
                                        label="Borough: (required)" 
                                        name="boroughNameID" /> */}
                                </Col>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        required
                                        label="Postcode:" 
                                        name="postcode" />
                                </Col>
                            </Row>
                            <FormManager.Input 
                                label="Who do you live with?" 
                                name="livingWithDetail" />
                            {age < 18 && (
                                <Row>
                                    <Col sm={6}>
                                        <FormManager.Input 
                                            label="Parent / Guardian" 
                                            name="parentGuardian" />
                                    </Col>
                                    <Col sm={6}>
                                        <FormManager.Input 
                                            label="Parent / Guardian Mobile:" 
                                            name="parentGuardianMobileNumber" />
                                    </Col>
                                </Row>
                            )}
                            <FormManager.Input 
                                required
                                label="Email address:" 
                                name="emailAddress" />
                            <Row>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        prepend={(
                                            <FormManager.Input 
                                                as={SelectDropdown.Graph}
                                                gql={MobileCountriesDropdownQuery}
                                                name="mobileCountryID"
                                                stripped
                                                clearable={false}
                                                value={1}
                                                defaultValue={1}
                                                manageOptions={(item: any) => ({...item, label: `+${item.label}`})}
                                                />
                                        )}
                                        required
                                        label="Mobile:" 
                                        name="mobileNumber" />
                                </Col>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        required
                                        label="Telephone:" 
                                        name="homeNumber" />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        label="Emergency Contact Name:" 
                                        name="emergencyContactName" />
                                </Col>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        label="Emergency Contact Number:" 
                                        name="emergencyContactNumber" />
                                </Col>
                            </Row>
                            {/* <FormManager.Input 
                                name="isBodySoulAware"
                                label="Aware of Body & Soul?"
                                as={CheckList}
                                options={[
                                    {
                                        label: 'Yes',
                                        value: 'Yes'
                                    },
                                    {
                                        label: 'No',
                                        value: 'No'
                                    },
                                ]}
                                type="radio"
                                inline /> */}
                                <fieldset>
                                    <legend>Do you have children?</legend>
                                    <FormManager.Input
                                        as={CheckList}
                                        options={[
                                            {
                                                label: 'Yes',
                                                value: 'Yes'
                                            },
                                            {
                                                label: 'No',
                                                value: 'No'
                                            },
                                        ]}
                                        type="radio"
                                        inline
                                        // label="Do you have children?" 
                                        name="hasChildren" />
                                    <FormManager.Condition name="hasChildren" showWhen={(value) => value === 'Yes'}>
                                        <div className="childrens-grid">
                                            <ChildrenFieldGrid />
                                        </div>
                                    </FormManager.Condition>
                            </fieldset>
                            <fieldset>
                                <legend>Are we ok to contact you by:</legend>
                                <Row>
                                    <Col sm={6}>
                                        <div className="d-flex justify-content-between">
                                            <FormManager.Boolean custom name="contactViaEmail" label="Email" />
                                            <FormManager.Boolean custom name="contactViaPost" label="Post" />
                                            <FormManager.Boolean custom name="contactViaPhoneCheckbox" label="Telephone" />
                                            <FormManager.Boolean custom name="contactViaText" label="Text" />
                                        </div>
                                    </Col>
                                </Row>
                                <FormManager.Input type="hidden" name="contactViaPhone" />
                            </fieldset>
                        </div>
                        <div className="mt-6">
                            <h3 className="referral-form-title">Physical and Emotional Health</h3>
                            <Row>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        label="GP Name:" 
                                        name="gpName" />
                                </Col>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        label="GP Surgery:" 
                                        name="gpSurgery" />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        label="Consultant:" 
                                        name="consultantNa" />
                                </Col>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        label="Hospital:" 
                                        name="hospital" />
                                </Col>
                            </Row>
                            <FormManager.Input 
                                label="Nurse Specialist:" 
                                name="nurseSpecialist" />
                            <FormManager.Input 
                                type="textarea"
                                rows={5}
                                label="Mental Health Professionals:" 
                                name="mentalHealthProfessionals" />
                            <FormManager.Input 
                                label="Social Worker:" 
                                name="socialWorkerName" />
                            <FormManager.Input 
                                type="textarea"
                                rows={5}
                                label="Other Professionals:" 
                                name="otherProfessionals" />
                            <FormManager.SpreadSheet 
                                name="health"
                                schema={{
                                    "id": {
                                        value: 0
                                    },
                                    "symptomsDiagnosis": {
                                        label: 'Symptoms / Diagnosis',
                                        value: ''
                                    },
                                    "onsetDate": {
                                        label: 'Onset Date',
                                        as: DatePicker,
                                        showYearDropdown: true
                                    },
                                    "formalDiagnosis": {
                                        label: 'Formal Diagnosis',
                                        value: ''
                                    },
                                }}
                                addLabel="Add Condition"
                            />
                            <FormManager.Input 
                                type="textarea"
                                rows={5}
                                label="Recent Health Service Detail:" 
                                name="recentHealthServiceDetail" />
                            <FormManager.Input 
                                name="isTakingPrescribedMedication"
                                label="Are you taking prescribed medication?"
                                as={CheckList}
                                options={[
                                    {
                                        label: 'Yes',
                                        value: 'Yes'
                                    },
                                    {
                                        label: 'No',
                                        value: 'No'
                                    },
                                ]}
                                type="radio"
                                inline />
                            <FormManager.Condition name="isTakingPrescribedMedication" showWhen={(value) => value === 'Yes'}>
                                <FormManager.Input 
                                    type="textarea"
                                    rows={5}
                                    label="How is it going? How are side effects? and How is adherence?" 
                                    name="prescribedMedicationDetail" />
                            </FormManager.Condition>
                            <FormManager.Input 
                                name="hasUsedDrugs"
                                label="Do you currently or have you in the past used street drugs or non-prescription drugs?"
                                as={CheckList}
                                options={[
                                    {
                                        label: 'Yes',
                                        value: 'Yes'
                                    },
                                    {
                                        label: 'No',
                                        value: 'No'
                                    },
                                ]}
                                type="radio"
                                inline />
                            <FormManager.Condition name="hasUsedDrugs" showWhen={(value) => value === 'Yes'}>
                                <FormManager.Input 
                                    type="textarea"
                                    rows={5}
                                    label="What did you use? and How often?" 
                                    name="drugsUsedDetail" />
                            </FormManager.Condition>
                            <FormManager.Input 
                                name="hasUsedAlcohol"
                                label="Do you currently or have you in the past used alcohol?"
                                as={CheckList}
                                options={[
                                    {
                                        label: 'Yes',
                                        value: 'Yes'
                                    },
                                    {
                                        label: 'No',
                                        value: 'No'
                                    },
                                ]}
                                type="radio"
                                inline />
                            <FormManager.Condition name="hasUsedAlcohol" showWhen={(value) => value === 'Yes'}>
                                <FormManager.Input 
                                    type="textarea"
                                    rows={5}
                                    label="How often on a weekly basis?" 
                                    name="alcoholUseFrequencyDetail" />
                            </FormManager.Condition>
                            <FormManager.Input 
                                type="textarea"
                                rows={5}
                                label="How is your sleep?" 
                                name="sleepDetail" />
                            <FormManager.Input 
                                type="textarea"
                                rows={5}
                                label="How is your eating? And have you ever seen a nutritionist?" 
                                name="eatingDetail" />
                            <FormManager.Input 
                                name="isEnvironmentSafe"
                                label="Do you feel safe in the environment you are living?"
                                as={CheckList}
                                options={[
                                    {
                                        label: 'Yes',
                                        value: 'Yes'
                                    },
                                    {
                                        label: 'No',
                                        value: 'No'
                                    },
                                ]}
                                type="radio"
                                inline />
                            <FormManager.Input 
                                type="textarea"
                                rows={5}
                                label=""
                                displayLabel={false} 
                                name="environmentSafetyDetail" />
                            <FormManager.Input 
                                type="textarea"
                                rows={5}
                                label="Environment/Context (Home/Work/Education/People):" 
                                name="environmentContextDetail" />
                            <FormManager.Input 
                                type="textarea"
                                rows={5}
                                label="What support have you had in managing your health and well being? And who do you feel you can reach out to for help and support?" 
                                name="wellnessSupportDetail" />
                            <FormManager.Input 
                                name="hasAttemptedSuicide"
                                label="Have you attempted suicide in the past?"
                                as={CheckList}
                                options={[
                                    {
                                        label: 'Yes',
                                        value: 'Yes'
                                    },
                                    {
                                        label: 'No',
                                        value: 'No'
                                    },
                                ]}
                                type="radio"
                                inline />
                            <FormManager.Condition name="hasAttemptedSuicide" showWhen={(value) => value === 'Yes'}>
                                <FormManager.Input 
                                    type="textarea"
                                    rows={5}
                                    label="When did you last attempt suicide? And what means did you use in your attempt?" 
                                    name="lastSuicideAttemptDetail" />
                                <FormManager.Input 
                                    type="textarea"
                                    rows={5}
                                    label="What was happening at the time that led to the recent attempt?" 
                                    name="lastSuicideAttemptSituationDetail" />
                                <FormManager.Input 
                                    type="textarea"
                                    rows={5}
                                    label="What interrupted the last attempt? And how did you feel about being interrupted?" 
                                    name="lastSuicideAttemptInterruptionDetail" />
                                <FormManager.Input 
                                    type="textarea"
                                    rows={5}
                                    label="Who knows about your suicide attempt(s)? And were they understanding?" 
                                    name="lastSuicideAttemptKnowledgeDetail" />
                                <FormManager.Input 
                                    type="textarea"
                                    rows={5}
                                    label="Have you made previous attempts beside this most recent one described? And how many, when, how?" 
                                    name="previousSuicideAttemptsDetail" />
                            </FormManager.Condition>
                            <FormManager.Input 
                                name="hasInflictedSelfHarm"
                                label="Have you ever self-harmed?"
                                as={CheckList}
                                options={[
                                    {
                                        label: 'Yes',
                                        value: 'Yes'
                                    },
                                    {
                                        label: 'No',
                                        value: 'No'
                                    },
                                ]}
                                type="radio"
                                inline />
                            <FormManager.Condition name="hasInflictedSelfHarm" showWhen={(value) => value === 'Yes'}>
                                <FormManager.Input 
                                    type="textarea"
                                    rows={5}
                                    label="What method did/do you use? And when did you last self harm?" 
                                    name="selfHarmDetail" />
                            </FormManager.Condition>
                            <FormManager.Input 
                                type="textarea"
                                rows={5}
                                label="What protective factors do you have in place? (e.g.friends,faith,animals,study,activism,creativity,sports)?" 
                                name="protectiveFactorsDetail" />
                        </div>
                        <div className="mt-6">
                            <h3 className="referral-form-title">Engagement Plan</h3>
                            <FormManager.Input 
                                type="textarea"
                                rows={5}
                                label="What would you like support with?" 
                                name="supportNeeded" />
                            <FormManager.Input 
                                type="textarea"
                                rows={5}
                                label="Strengths/Likes/Interests:" 
                                name="strengthsLikesInterests" />
                            <FormManager.Input 
                                type="textarea"
                                rows={5}
                                label="Do any other family members access, or you would like to access, the Body & Soul services?" 
                                name="familyAccess" />
                            <FormManager.Input 
                                type="textarea"
                                rows={5}
                                label="Do you have any special accessibility needs? (mobility/visual/audio)" 
                                name="accessNeeds" />
                            {/* <Row>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        label="Assessment completed by:" 
                                        name="assessmentCompletedBy"/>
                                </Col>
                                <Col sm={6}>
                                    <FormManager.Input
                                        as={DatePicker}
                                        label="Date of assessment:" 
                                        name="assessmentDate"
                                            />
                                </Col>
                            </Row> */}
                            {/* <Row>
                                <Col sm={6}>
                                    <FormManager.Input 
                                        label="Triage attendance:" 
                                        name="triageAttendance"/>
                                </Col>
                                <Col sm={6}>
                                    <FormManager.Input
                                        as={DatePicker}
                                        label="Date of Triage:" 
                                        name="triageDate"
                                            />
                                </Col>
                            </Row>
                            <FormManager.Input 
                                type="textarea"
                                rows={5}
                                label="Triage notes:" 
                                name="triageNotes" /> */}
                            {/* <FormManager.Input 
                                type="textarea"
                                rows={5}
                                label="Engagement Plan:" 
                                name="engagementPlan" /> */}
                        </div>
                        <div className="mt-6">
                            <h3 className="referral-form-title">Program Details</h3>
                            <FormManager.Input
                                as={SelectDropdown.Graph}
                                gql={ProgramTypesDropdownQuery}
                                name="programTypeID"
                                label="Programs"
                                required
                                fetchPolicy="cache-first"
                            />
                            <FormManager.Input
                                as={SelectDropdown}
                                options={categoriesOptions || []}
                                required
                                disabled={!programID || !categoriesOptions?.length}
                                name="programCategoryID"
                                label="Program Sub Category"
                            />
                            <FormManager.Input 
                                type="textarea"
                                rows={5}
                                label="Remarks" 
                                name="remarks" 
                                />
                        </div>
                        <Button type="submit">Submit</Button>
                    </FormManager.Core>
                )}
                <DisconnectedJobLoader active={requestReferralForm.loading} className="progress-pink">
                    <h4>Submitting Referral</h4>
                </DisconnectedJobLoader>
        </div>
    )
}

export default FinalAssessment
