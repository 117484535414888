import React, { useRef, useMemo } from "react";
import Button from "react-bootstrap/Button";
import FormModal from "@whitecobalt/tungsten/Modal/Form";
import FormManager, {
    GridFieldReferenceType,
} from "@whitecobalt/tungsten/FormManager";
import Toaster from "@whitecobalt/tungsten/Toaster";
import ConfirmModal from "@whitecobalt/tungsten/Modal/Confirm";
import Icon from "@whitecobalt/tungsten/Icon";
import { MemberPropType } from "@services/query/grid/MembersQuery";
import DatePicker from "@whitecobalt/tungsten/Fields/Date";
import { dateFormat } from "@whitecobalt/tungsten/utils/dateFormat";

interface ChildrenFieldGridProps {
    data?: MemberPropType;
}

const ChildrenFieldGrid: React.FunctionComponent<ChildrenFieldGridProps> = ({ data }) => {
    const gridFieldReference = useRef<GridFieldReferenceType>(null);


    const handleAddEditRelationship = (_: any, item: any = {}, index: any) => {
        const isNewRelationship = typeof item.id === "undefined";
        // after the promise is done we need to set another one that is why we recursive when the request is failed
        const recursivePromptForm = () => {
            FormModal({
                key: "add-edit",
                className: "modal-referral-form",
                title: `${isNewRelationship ? "Add" : "Edit"} Child`,
                titleIcon: `fa fa-${isNewRelationship ? "plus" : "pen"} fa-lg`,
                submit: `${isNewRelationship ? "Add" : "Save"}`,
                body: (
                    <>
                        <FormManager.Input
                            required
                            name="firstname"
                            label="First Name"
                            value={item?.firstname || ""}
                            rules="required"
                            />
                        <FormManager.Input
                            required
                            name="surname"
                            label="Last Name"
                            value={item?.surname || ""}
                            rules="required"
                            />
                        <FormManager.Input
                            as={DatePicker}
                            required
                            name="dateOfBirth"
                            label="Date of Birth"
                            value={item?.dateOfBirth ? new Date(item.dateOfBirth) : undefined}
                            />
                        <FormManager.Boolean
                            name="isLivingWithYou"
                            label="Does the child live with you?"
                            checked={item?.isLivingWithYou}/>
                        <FormManager.Boolean
                            name="isAwareOfBodySoul"
                            label="Is this child aware you are attending Body & Soul?"
                            checked={item?.isAwareOfBodySoul}
                            />
                        <FormManager.Boolean
                            name="willAccessBodySoul"
                            label="Would you like the child to access Body & Soul services?"
                            checked={item?.willAccessBodySoul}/>
                    </>
                ),
            }).then((event) => {
                if (event.isSubmit) {
                    const FORM_DATA = event.json();
                    if (event.isReady()) {
                        if (isNewRelationship) {
                            gridFieldReference.current?.addRow({
                                firstname: FORM_DATA.firstname,
                                surname: FORM_DATA.surname,
                                dateOfBirth: FORM_DATA.dateOfBirth,
                                isLivingWithYou: FORM_DATA.isLivingWithYou,
                                willAccessBodySoul: FORM_DATA.willAccessBodySoul,
                                isAwareOfBodySoul: FORM_DATA.isAwareOfBodySoul,
                            });

                            // Toaster.success(`Successfully added Relationship`);
                        } else {
                            gridFieldReference.current?.editRow({
                                firstname: FORM_DATA.firstname,
                                surname: FORM_DATA.surname,
                                dateOfBirth: FORM_DATA.dateOfBirth,
                                isLivingWithYou: FORM_DATA.isLivingWithYou,
                                willAccessBodySoul: FORM_DATA.willAccessBodySoul,
                                isAwareOfBodySoul: FORM_DATA.isAwareOfBodySoul,
                            },index);

                            // Toaster.success(`Successfully edited Relationship`);
                        }

                        event.closeModal();
                    } else {
                        event.removeLoading();
                        recursivePromptForm();
                        Toaster.fail();
                    }
                }
            });
        };

        recursivePromptForm();
    };

    const handleDeleteRelationship = (_: any, item: any, index: number) => {
        ConfirmModal({
            title: "Remove Child",
            titleIcon: "fas fa-trash fa-lg",
            className: "modal-referral-form",
            body: <>You are Removing Child</>,
            submit: "Remove",
        }).then((confirmed) => {
            if (confirmed) {
                gridFieldReference.current?.removeRow(index);
                // Toaster.success(`Successfully removed Relationship`);
            }
        });
    };

    return (
        <div className="position-relative">
            <div className="d-flex justify-content-end mb-5">
                <Button type="button" onClick={handleAddEditRelationship as any}>
                    <Icon name="plus" /> Add Child
                </Button>
            </div>
            <FormManager.Grid
                ref={gridFieldReference}
                name="children"
                asynchronous
                schema={{
                    id: {
                        value: 0,
                    },
                    firstname: {
                        label: 'First Name',
                        render: (Firstname) => Firstname || '-'
                    },
                    surname: {
                        label: 'Last Name',
                        render: (Surname) => Surname || '-'
                    },
                    dateOfBirth: {
                        label: "Date of birth",
                        render: (dateOfBirth) => dateOfBirth ? dateFormat(dateOfBirth, 'en-GB') : '-'
                    },
                    isLivingWithYou: {
                        label: 'Lives with you?',
                        render: (isLivingWithYou) => typeof isLivingWithYou === 'boolean' ? isLivingWithYou ? 'Yes' : 'No' : '-'
                    },
                    willAccessBodySoul: {
                        label: 'Access to Body & Soul?',
                        render: (willAccessBodySoul) => typeof willAccessBodySoul === 'boolean' ? willAccessBodySoul ? 'Yes' : 'No' : '-'
                    },
                    isAwareOfBodySoul: {
                        label: 'Aware of Body and Soul?',
                        render: (isAwareOfBodySoul) => typeof isAwareOfBodySoul === 'boolean' ? isAwareOfBodySoul ? 'Yes' : 'No' : '-'
                    }
                }}
                actions={[
                    {
                        icon: "fa fa-pen",
                        title: "edit child",
                        onClick: handleAddEditRelationship,
                    },
                    {
                        icon: "fa fa-times",
                        title: "remove child",
                        onClick: handleDeleteRelationship,
                    },
                ]}
            />
        </div>
    );
};

export default ChildrenFieldGrid;
