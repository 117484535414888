/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {
	useState,
	useMemo,
	useRef,
	useEffect,
	useCallback,
} from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import {
	NotificationsQuery,
	NotificationsUpdateQuery,
} from "@services/query/NotificationsQuery";
import { useSession } from "@whitecobalt/tungsten/Session";
import { useHistory } from "react-router-dom";
import { useAuthAPI } from "@whitecobalt/tungsten/hooks/useAuthAPI";
import { useObservable } from "@whitecobalt/tungsten/hooks/useObservable";
import { useLazyQuery } from "@apollo/client";
import Icon from "@whitecobalt/tungsten/Icon";
import classNames from "classnames";
import { dateTimeFormat } from "@whitecobalt/tungsten/utils/dateFormat";
import Facade from "@whitecobalt/tungsten/Facade";
import Badge from "react-bootstrap/Badge";
import branding from "@config/branding";
import { useEffectMounted } from "@whitecobalt/tungsten/esm/common/hooks/useEffectMounted";

const perPage = 10;

export function UserNotificationsDropdown() {
	const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

	const uiService = useHtmlClassService();
	const layoutProps = useMemo(() => {
		return {
			offcanvas:
				objectPath.get(uiService.config, "extras.notifications.layout") ===
				"offcanvas",
		};
	}, [uiService]);

	const [{ user, others }] = useSession();
	const history = useHistory();
	const readNotification = useAuthAPI("/api/NotificationRead");
	const readAllNotification = useAuthAPI("/api/NotificationRead/all");
	const [observables] = useObservable({
		page: 1,
		offetNewNotif: 0,
		initializeUnreads: false,
		initializeData: false,
		refetching: false
	})

	const [{ data, loading, totalUnread }, setStates] = useState({
		data: [],
		loading: true,
		totalUnread: 0,
	});

	const [loadAllNotif, requestAllNotif] = useLazyQuery(NotificationsQuery, {
		fetchPolicy: "no-cache",
		notifyOnNetworkStatusChange: true,
		variables: {
			take: perPage,
			skip: 0,
			order: { DateTimeStamp: "DESC" },
			where: {
				UserID: {
					equals: user?.id,
				},
			},
		},
	});

	const [loadUnreadNotif, requestUnreadNotif] = useLazyQuery(
		NotificationsUpdateQuery,
		{
			fetchPolicy: "no-cache",
			variables: {
				where: {
					UserID: {
						equals: user?.id,
					},
					IsRead: {
						equals: false,
					},
				},
			},
		}
	);

	useEffect(() => {
		if (others.menuListLoaded) {
			loadUnreadNotif();
			loadAllNotif();
		}
	}, [others.menuListLoaded]);

	useEffect(() => {
		if (others.menuListLoaded) {
			const cleanup = setInterval(() => {
				if (requestUnreadNotif?.refetch)
					requestUnreadNotif?.refetch().then((response) => {
						const count = response.data?.updates.totalCount || 0;
						if (count !== totalUnread && count > totalUnread) {
							observables.offetNewNotif += count - totalUnread
							requestAllNotif?.refetch({
								take: count - totalUnread,
								skip: 0
							}).then((response) => {
								const newItems = response.data?.Notifications?.items || [];
								setStates((prev) => ({
									...prev,
									data: [ ...newItems, ...prev.data],
									totalUnread: count,
								}))
							})
							const sound = new Audio(branding.notification.pathSoundEffect);
							sound.play();
						}
					});
			}, 10000);

			return () => clearInterval(cleanup);
		}
	}, [others.menuListLoaded, totalUnread]);

	useEffectMounted(() => {
		if (observables.initializeData === false) {
			observables.initializeData = true
			const items = requestAllNotif.data?.Notifications?.items || [];
			setStates((prev) => ({
				...prev,
				data: [...prev.data, ...items],
				loading: false
			}));
		}
	}, [requestAllNotif.data]);

	useEffectMounted(() => {
		if (observables.initializeUnreads === false) {
			observables.initializeUnreads = true;
			const totalUnread = requestUnreadNotif.data?.updates.totalCount || 0;
			setStates((prev) => ({
				...prev,
				totalUnread,
			}));
		}
	}, [requestUnreadNotif.data?.updates.totalCount]);

	const handleSeen = useCallback((itemID) => {
		readNotification
			.call({
				data: {
					NotificationID: itemID,
				},
			})
			.then(() => {
				if (requestUnreadNotif?.refetch) requestUnreadNotif?.refetch();
			});

		setStates((prev) => ({
			...prev,
			totalUnread: prev.totalUnread - 1,
			data: prev.data.map((each) => ({
				...each,
				IsRead: each.IsRead || each.ID === itemID,
			})),
		}));
	}, []);

	const handleScrollEnd = () => {
		if (
			requestAllNotif.data?.Notifications?.pageInfo.hasNextPage &&
			observables.refetching === false
		) {
			setStates(prev => ({...prev, loading: true}))
			observables.refetching = true;
			observables.page += 1;

			requestAllNotif
				?.refetch({
					take: perPage,
					skip: observables.offetNewNotif + (perPage * observables.page - perPage),
				})
				.then((response) => {
					const newItems = response.data?.Notifications?.items || [];
					setStates((prev) => ({
						...prev,
						data: [...prev.data, ...newItems],
						loading: false
					}))
					observables.refetching = false;
				});
		}
	};

	const hasNotification = data.length > 0

	return (
		<>
			{layoutProps.offcanvas && (
				<div className="topbar-item">
					<div
						className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
						id="kt_quick_notifications_toggle"
					>
						<span className="svg-icon svg-icon-xl svg-icon-primary">
							<SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
						</span>
						<span className="pulse-ring"></span>
					</div>
				</div>
			)}
			{!layoutProps.offcanvas && (
				<Dropdown drop="down" alignRight>
					<Dropdown.Toggle
						as={DropdownTopbarItemToggler}
						id="kt_quick_notifications_toggle"
					>
						<OverlayTrigger
							placement="bottom"
							overlay={
								<Tooltip id="user-notification-tooltip">
									User Notifications
								</Tooltip>
							}
						>
							<div
								className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
								id="kt_quick_notifications_toggle"
							>
								<span className="svg-icon svg-icon-xl svg-icon-primary">
									<SVG
										src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}
									/>
								</span>
								{!!totalUnread && (
									<>
										<Badge variant="danger" className="notification-badge">
											{totalUnread}
										</Badge>
										<span className="pulse-ring"></span>
										<span className="pulse-ring" />
									</>
								)}
							</div>
						</OverlayTrigger>
					</Dropdown.Toggle>

					<Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
						<form>
							{/** Head */}
							<div
								className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
								style={{ backgroundImage: `url(${bgImage})` }}
							>
								<div className="position-relative">
									<h4 className="d-flex flex-center rounded-top mb-10">
										<span className="text-white">User Notifications</span>
										<span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
											{totalUnread} new
										</span>
									</h4>
									<Dropdown className="position-absolute" style={{top: 5, right: 5}}>
										<Dropdown.Toggle as="a">
											<Icon name="ellipsis-v" className="text-center text-muted text-hover-white" size={20} style={{width: 20}}/>
										</Dropdown.Toggle>
										<Dropdown.Menu align="right">
											<Dropdown.Item
												onClick={(e) => {
													e.stopPropagation();
													e.preventDefault();
													readAllNotification.call().then(() => {
														if (requestUnreadNotif?.refetch) requestUnreadNotif?.refetch();
													});
										
													setStates((prev) => ({
														...prev,
														totalUnread: 0,
														data: prev.data.map((each) => ({
															...each,
															IsRead: true
														})),
													}))
												}}
											>
												Mark all as Read
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
								<div className="p-5 bg-white">
									{loading && observables.initializeData === false ? (
										new Array(3).fill(0).map((_, index) => {
											return (
												<div
													key={index}
													className={classNames("notification-item", {
														read: true,
													})}
												>
													<div className="d-flex align-items-center">
														<div className="d-flex flex-column font-weight-bold w-100">
															<a
																href="#"
																className="text-dark text-hover-primary mb-1 font-size-lg notification-title"
															>
																<div>
																	<Facade
																		loading
																		padding={false}
																		rows={0}
																		title
																	/>
																</div>
															</a>
															<span className="text-muted">
																<Facade loading padding={false} rows={2} />
															</span>
														</div>
													</div>
												</div>
											);
										})
									) : hasNotification ? (
										<PerfectScrollbar
											options={{
												wheelSpeed: 2,
												wheelPropagation: false,
											}}
											className="scroll pr-7 mr-n7"
											onYReachEnd={handleScrollEnd}
											style={{
												maxHeight: "300px",
												position: "relative",
												height: "300px",
											}}
										>
											{data.map((item, index) => (
												<div
													key={index}
													className={classNames("notification-item", {
														read: item.IsRead,
													})}
													onClick={() => {
														if (item.IsRead === false) {
															handleSeen(item.ID);
														}
														const thepathname = (item.Link || '')?.replace(window.location.origin, '')
														history.push(`${/^\//.test(thepathname) === false ? '/' : ''}${thepathname}`);
													}}
												>
													{!item.IsRead && (
														<div
															className="notification-actions"
															onClick={(e) => e.stopPropagation()}
														>
															<Dropdown className="notification-actions-dropdown">
																<Dropdown.Toggle as="a">
																	<Icon name="ellipsis-h" />
																</Dropdown.Toggle>
																<Dropdown.Menu>
																	<Dropdown.Item
																		onClick={(e) => {
																			e.stopPropagation();
																			e.preventDefault();
																			handleSeen(item.ID);
																		}}
																	>
																		Mark as Read
																	</Dropdown.Item>
																</Dropdown.Menu>
															</Dropdown>
														</div>
													)}
													<div className="d-flex align-items-center">
														{/* <div className="symbol symbol-40 symbol-light-primary mr-5">
								<span className="symbol-label">
									<SVG
									src={toAbsoluteUrl(
										"/media/svg/icons/Home/Library.svg"
									)}
									className="svg-icon-lg svg-icon-primary"
									></SVG>
								</span>
								</div> */}
														<div className="d-flex flex-column font-weight-bold w-100">
															<div className="text-dark text-hover-primary mb-1 font-size-lg notification-title">
																<section className="d-flex justify-content-between align-items-start w-100">
																	<div>{item.Title}</div>{" "}
																	<span className="badge badge-light fs-8">
																		{dateTimeFormat(
																			item.DateTimeStamp,
																			"en-GB"
																		)}
																	</span>
																</section>
															</div>
															<span className="text-muted">
																{item.Description}
															</span>
														</div>
													</div>
												</div>
											))}
											{loading && "Loading More"}
											{(loading) && (
												new Array(perPage).fill(0).map((_, index) => {
													return (
														<div
															key={index}
															className={classNames("notification-item", {
																read: true,
															})}
														>
															<div className="d-flex align-items-center">
																<div className="d-flex flex-column font-weight-bold w-100">
																	<a
																		href="#"
																		className="text-dark text-hover-primary mb-1 font-size-lg notification-title"
																	>
																		<div>
																			<Facade
																				loading
																				padding={false}
																				rows={0}
																				title
																			/>
																		</div>
																	</a>
																	<span className="text-muted">
																		<Facade loading padding={false} rows={2} />
																	</span>
																</div>
															</div>
														</div>
													);
												})
											)}
										</PerfectScrollbar>
									) : (
										<div className="notification-no-record">
											<Icon name="bell-slash" />
											<p>No Notifications</p>
										</div>
									)}
								</div>
								{/* <Tab.Container defaultActiveKey={key}>
					<Nav
						as="ul"
						className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
						onSelect={(_key) => setKey(_key)}
					>
						<Nav.Item className="nav-item" as="li">
						<Nav.Link
							eventKey="Alerts"
							className={`nav-link show ${
							key === "Alerts" ? "active" : ""
							}`}
						>
							Alerts
						</Nav.Link>
						</Nav.Item>
						<Nav.Item as="li">
						<Nav.Link
							eventKey="Events"
							className={`nav-link show ${
							key === "Events" ? "active" : ""
							}`}
						>
							Events
						</Nav.Link>
						</Nav.Item>
						<Nav.Item as="li">
						<Nav.Link
							eventKey="Logs"
							className={`nav-link show ${
							key === "Logs" ? "active" : ""
							}`}
						>
							Logs
						</Nav.Link>
						</Nav.Item>
					</Nav>

					<Tab.Content className="tab-content">
						<Tab.Pane eventKey="Alerts" className="p-8">
						<PerfectScrollbar
							options={perfectScrollbarOptions}
							className="scroll pr-7 mr-n7"
							style={{ maxHeight: "300px", position: "relative" }}
						>
							<div className="d-flex align-items-center mb-6">
							<div className="symbol symbol-40 symbol-light-primary mr-5">
								<span className="symbol-label">
								<SVG
									src={toAbsoluteUrl(
									"/media/svg/icons/Home/Library.svg"
									)}
									className="svg-icon-lg svg-icon-primary"
								></SVG>
								</span>
							</div>
							<div className="d-flex flex-column font-weight-bold">
								<a
								href="#"
								className="text-dark text-hover-primary mb-1 font-size-lg"
								>
								Briviba SaaS
								</a>
								<span className="text-muted">
								PHP, SQLite, Artisan CLIмм
								</span>
							</div>
							</div>
							<div className="d-flex align-items-center mb-6">
							<div className="symbol symbol-40 symbol-light-warning mr-5">
								<span className="symbol-label">
								<SVG
									src={toAbsoluteUrl(
									"/media/svg/icons/Communication/Write.svg"
									)}
									className="svg-icon-lg svg-icon-warning"
								></SVG>
								</span>
							</div>
							<div className="d-flex flex-column font-weight-bold">
								<a
								href="#"
								className="text-dark-75 text-hover-primary mb-1 font-size-lg"
								>
								Briviba SaaS
								</a>
								<span className="text-muted">
								PHP, SQLite, Artisan CLIмм
								</span>
							</div>
							</div>
							<div className="d-flex align-items-center mb-6">
							<div className="symbol symbol-40 symbol-light-success mr-5">
								<span className="symbol-label">
								<SVG
									src={toAbsoluteUrl(
									"/media/svg/icons/Communication/Group-chat.svg"
									)}
									className="svg-icon-lg svg-icon-success"
								></SVG>
								</span>
							</div>
							<div className="d-flex flex-column font-weight-bold">
								<a
								href="#"
								className="text-dark text-hover-primary mb-1 font-size-lg"
								>
								Briviba SaaS
								</a>
								<span className="text-muted">
								PHP, SQLite, Artisan CLIмм
								</span>
							</div>
							</div>
							<div className="d-flex align-items-center mb-6">
							<div className="symbol symbol-40 symbol-light-danger mr-5">
								<span className="symbol-label">
								<SVG
									src={toAbsoluteUrl(
									"/media/svg/icons/General/Attachment2.svg"
									)}
									className="svg-icon-lg svg-icon-danger"
								></SVG>
								</span>
							</div>
							<div className="d-flex flex-column font-weight-bold">
								<a
								href="#"
								className="text-dark text-hover-primary mb-1 font-size-lg"
								>
								Briviba SaaS
								</a>
								<span className="text-muted">
								PHP, SQLite, Artisan CLIмм
								</span>
							</div>
							</div>
							<div className="d-flex align-items-center mb-2">
							<div className="symbol symbol-40 symbol-light-info mr-5">
								<span className="symbol-label">
								<SVG
									src={toAbsoluteUrl(
									"/media/svg/icons/General/Attachment2.svg"
									)}
									className="svg-icon-lg svg-icon-info"
								></SVG>
								</span>
							</div>
							<div className="d-flex flex-column font-weight-bold">
								<a
								href="#"
								className="text-dark text-hover-primary mb-1 font-size-lg"
								>
								Briviba SaaS
								</a>
								<span className="text-muted">
								PHP, SQLite, Artisan CLIмм
								</span>
							</div>
							</div>
							<div className="d-flex align-items-center mb-2">
							<div className="symbol symbol-40 symbol-light-info mr-5">
								<span className="symbol-label">
								<SVG
									src={toAbsoluteUrl(
									"/media/svg/icons/Communication/Mail-notification.svg"
									)}
									className="svg-icon-lg svg-icon-info"
								></SVG>
								</span>
							</div>
							<div className="d-flex flex-column font-weight-bold">
								<a
								href="#"
								className="text-dark text-hover-primary mb-1 font-size-lg"
								>
								Briviba SaaS
								</a>
								<span className="text-muted">
								PHP, SQLite, Artisan CLIмм
								</span>
							</div>
							</div>
							<div className="d-flex align-items-center mb-2">
							<div className="symbol symbol-40 symbol-light-info mr-5">
								<span className="symbol-label">
								<SVG
									src={toAbsoluteUrl(
									"/media/svg/icons/Design/Bucket.svg"
									)}
									className="svg-icon-lg svg-icon-info"
								></SVG>
								</span>
							</div>
							<div className="d-flex flex-column font-weight-bold">
								<a
								href="#"
								className="text-dark text-hover-primary mb-1 font-size-lg"
								>
								Briviba SaaS
								</a>
								<span className="text-muted">
								PHP, SQLite, Artisan CLIмм
								</span>
							</div>
							</div>
						</PerfectScrollbar>
						</Tab.Pane>
						<Tab.Pane
						eventKey="Events"
						id="topbar_notifications_events"
						>
						<PerfectScrollbar
							options={perfectScrollbarOptions}
							className="navi navi-hover scroll my-4"
							style={{ maxHeight: "300px", position: "relative" }}
						>
							<a href="#" className="navi-item">
							<div className="navi-link">
								<div className="navi-icon mr-2">
								<i className="flaticon2-line-chart text-success"></i>
								</div>
								<div className="navi-text">
								<div className="font-weight-bold">
									New report has been received
								</div>
								<div className="text-muted">23 hrs ago</div>
								</div>
							</div>
							</a>

							<a href="#" className="navi-item">
							<div className="navi-link">
								<div className="navi-icon mr-2">
								<i className="flaticon2-paper-plane text-danger"></i>
								</div>
								<div className="navi-text">
								<div className="font-weight-bold">
									Finance report has been generated
								</div>
								<div className="text-muted">25 hrs ago</div>
								</div>
							</div>
							</a>

							<a href="#" className="navi-item">
							<div className="navi-link">
								<div className="navi-icon mr-2">
								<i className="flaticon2-user flaticon2-line- text-success"></i>
								</div>
								<div className="navi-text">
								<div className="font-weight-bold">
									New order has been received
								</div>
								<div className="text-muted">2 hrs ago</div>
								</div>
							</div>
							</a>

							<a href="#" className="navi-item">
							<div className="navi-link">
								<div className="navi-icon mr-2">
								<i className="flaticon2-pin text-primary"></i>
								</div>
								<div className="navi-text">
								<div className="font-weight-bold">
									New customer is registered
								</div>
								<div className="text-muted">3 hrs ago</div>
								</div>
							</div>
							</a>

							<a href="#" className="navi-item">
							<div className="navi-link">
								<div className="navi-icon mr-2">
								<i className="flaticon2-sms text-danger"></i>
								</div>
								<div className="navi-text">
								<div className="font-weight-bold">
									Application has been approved
								</div>
								<div className="text-muted">3 hrs ago</div>
								</div>
							</div>
							</a>

							<a href="#" className="navi-item">
							<div className="navi-link">
								<div className="navi-icon mr-2">
								<i className="flaticon2-pie-chart-3 text-warning"></i>
								</div>
								<div className="navinavinavi-text">
								<div className="font-weight-bold">
									New file has been uploaded
								</div>
								<div className="text-muted">5 hrs ago</div>
								</div>
							</div>
							</a>

							<a href="#" className="navi-item">
							<div className="navi-link">
								<div className="navi-icon mr-2">
								<i className="flaticon-pie-chart-1 text-info"></i>
								</div>
								<div className="navi-text">
								<div className="font-weight-bold">
									New user feedback received
								</div>
								<div className="text-muted">8 hrs ago</div>
								</div>
							</div>
							</a>

							<a href="#" className="navi-item">
							<div className="navi-link">
								<div className="navi-icon mr-2">
								<i className="flaticon2-settings text-success"></i>
								</div>
								<div className="navi-text">
								<div className="font-weight-bold">
									System reboot has been successfully completed
								</div>
								<div className="text-muted">12 hrs ago</div>
								</div>
							</div>
							</a>

							<a href="#" className="navi-item">
							<div className="navi-link">
								<div className="navi-icon mr-2">
								<i className="flaticon-safe-shield-protection text-primary"></i>
								</div>
								<div className="navi-text">
								<div className="font-weight-bold">
									New order has been placed
								</div>
								<div className="text-muted">15 hrs ago</div>
								</div>
							</div>
							</a>

							<a href="#" className="navi-item">
							<div className="navi-link">
								<div className="navi-icon mr-2">
								<i className="flaticon2-notification text-primary"></i>
								</div>
								<div className="navi-text">
								<div className="font-weight-bold">
									Company meeting canceled
								</div>
								<div className="text-muted">19 hrs ago</div>
								</div>
							</div>
							</a>

							<a href="#" className="navi-item">
							<div className="navi-link">
								<div className="navi-icon mr-2">
								<i className="flaticon2-fax text-success"></i>
								</div>
								<div className="navi-text">
								<div className="font-weight-bold">
									New report has been received
								</div>
								<div className="text-muted">23 hrs ago</div>
								</div>
							</div>
							</a>

							<a href="#" className="navi-item">
							<div className="navi-link">
								<div className="navi-icon mr-2">
								<i className="flaticon-download-1 text-danger"></i>
								</div>
								<div className="navi-text">
								<div className="font-weight-bold">
									Finance report has been generated
								</div>
								<div className="text-muted">25 hrs ago</div>
								</div>
							</div>
							</a>

							<a href="#" className="navi-item">
							<div className="navi-link">
								<div className="navi-icon mr-2">
								<i className="flaticon-security text-warning"></i>
								</div>
								<div className="navi-text">
								<div className="font-weight-bold">
									New customer comment recieved
								</div>
								<div className="text-muted">2 days ago</div>
								</div>
							</div>
							</a>

							<a href="#" className="navi-item">
							<div className="navi-link">
								<div className="navi-icon mr-2">
								<i className="flaticon2-analytics-1 text-success"></i>
								</div>
								<div className="navi-text">
								<div className="font-weight-bold">
									New customer is registered
								</div>
								<div className="text-muted">3 days ago</div>
								</div>
							</div>
							</a>
						</PerfectScrollbar>
						</Tab.Pane>
						<Tab.Pane eventKey="Logs" id="topbar_notifications_logs">
						<div className="d-flex flex-center text-center text-muted min-h-200px">
							All caught up!
							<br />
							No new notifications.
						</div>
						</Tab.Pane>
					</Tab.Content>
					</Tab.Container>
				*/}
							</div>
						</form>
					</Dropdown.Menu>
				</Dropdown>
			)}
		</>
	);
}
